import {
	createApp
} from 'vue'
import 'element-plus/lib/theme-chalk/index.css'
import ElementPlus from 'element-plus'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import api from './axios/api'
import uri from './axios/url'
import utils from './util/util'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// 引入全局样式
import './assets/css/index.styl'
import {
	signature
} from './axios/fetch'

const myApp = createApp(App)

myApp.config.globalProperties.$axios = axios
myApp.config.globalProperties.api = api
myApp.config.globalProperties.uri = uri
myApp.config.globalProperties.timeToDate = utils.dateFormat
myApp.config.globalProperties.signature = signature


myApp.use(router).use(ElementPlus, {
	locale: zhCn
}).mount('#app')
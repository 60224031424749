/* eslint-disable */
import axios from 'axios'
// import store from '../store'
import {
	ElMessageBox,
	ElMessage
} from 'element-plus'
import CryptoJS from 'crypto-js'
import qs from 'qs'
export function fetch(options) {
	return new Promise((resolve, reject) => {
		let timestamp = new Date().getTime()
		let header = {
			'token': JSON.parse(localStorage.getItem('user_info'))["token"],
			'client_time': parseInt(timestamp / 1000),
			'uid': JSON.parse(localStorage.getItem('user_info'))["id"],
			'request_id': timestamp,
			'CA-KEYS': 'client_time,request_id,token,uid',
			region: localStorage.getItem('region_change')
		}
		header.signature = signature(timestamp, options, header)
		header['Content-Type'] = 'application/json'
		const instance = axios.create({
			headers: header,
			transformRequest: [function(data) {
				data = JSON.stringify(data)
				return data
			}],
			timeout: 300 * 1000
		});

		// then 请求成功之后进行什么操作
		instance(options).then(response => {
			if (response.data.msg == '请先登录' || response.data.msg == '登录已失效') {
				ElMessage('请先登录')
				localStorage.setItem("isLogin", false)
				window.location.href = '/'
				localStorage.clear()
			} else if (response.data.msg == '您可能在其它地方已登录') {
				ElMessage('您可能在其它地方已登录,请重新登录！')
				setTimeout(() => {
					localStorage.setItem("isLogin", false)
					window.location.href = '/'
					localStorage.clear()
				}, 1000)
			} else if (response.data.msg.indexOf('token is expired by') !== -1) {
				ElMessage('token已经过期，请重新登录')
				localStorage.setItem("isLogin", false)
				window.location.href = '/'
				localStorage.clear()
			} else {
				// 把请求到的数据发到引用请求的地方
				resolve(response);
			}
		}).catch(error => {
			ElMessage('服务器请求错误')
			console.log('请求异常信息：' + error)
			reject(error)
		});
	});
}

// 签名结果
export function signature(request_id, config, headers) {
	var signature = GetHeaderString(headers)
	var queryBody = ""



	if (config.method === "get") {
		queryBody = GetQueryString(config);
	} else {
		queryBody = GetBodyString(config);
	}

	if (queryBody != "") {
		queryBody = CryptoJS.MD5(queryBody).toString().toUpperCase()
		signature = signature + queryBody
	} else {
		signature = signature
	}

	return CryptoJS.HmacSHA256(signature, request_id + '');
}

function GetQueryString(config) {
	var queryString = SortString(config.params)
	return queryString;
}

function GetBodyString(config) {
	if (config.data == "") {
		return "";
	}

	return JSON.stringify(config.data);
}

function GetHeaderString(headers) {
	var keys = headers["CA-KEYS"].split(",")
	var signHeader = []
	for (const v of keys) {
		signHeader[v] = headers[v]
	}
	// 执行序列化
	var str = SortString(signHeader)
	return str;
}

function isObject(obj) {
	return Object.prototype.toString.call(obj) === '[object Object]';
}
// 排序方法
function SortString(obj) {
	var keys = Object.keys(obj).sort();
	var str = "";
	// let data = {
	// 	name: "ddd"
	// }
	// let a = isObject(data)
	keys.forEach(key => {
		if (obj[key] == undefined || obj[key] == null) {
			return;
		}

		if (str == "") {
			if (isObject(obj[key])) {
				str += key + "=" + JSON.stringify(obj[key]);
			} else {
				str += key + "=" + obj[key];
			}
			return true;
		}
		str += "&" + key + "=" + obj[key];
	});
	return str;
}
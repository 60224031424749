import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'
import routes from './routes'


// const routerHistory = createWebHistory()
// const routerHashHistory = createWebHashHistory()
const router = createRouter({
	// history: routerHistory,
	history: createWebHashHistory(),
	routes: routes
})
// router.beforeEach((to, from, next) => {
//     if (to.meta.auth) {
//     //   document.documentElement.scrollTop = 0
//     //   document.body.scrollTop = 0
//       const loginFlag = localStorage.getItem('is_login')
//       if (loginFlag === '1') {
//         next()
//       } else {
//         next({
//           path: '/'
//         })
//       }
//     } else {
//     //   document.documentElement.scrollTop = 0
//     //   document.body.scrollTop = 0
//       next()
//     }
//   })
export default router
import SparkMD5 from 'spark-md5'
const util = { }
// 格式化时间东八区
util.dateFormat1 = function (value) {
    if (!value) {
        return '--'
    }
    var b = new Date(value*1000);
    var year = b.getFullYear()+'-';
    var month = (b.getMonth()+1);
    var date = b.getDate();
    var hour = b.getHours();
    var min = b.getMinutes();
    var second = b.getSeconds();
    if(month<10){
        month = '0'+ (b.getMonth()+1)+'-';
    }else {
        month = (b.getMonth()+1)+'-';
    }
    if(date<10){
        date = '0'+ (b.getDate());
    }
    if(hour<10){
        hour = '0'+ (b.getHours())+':';
    }else {
        hour = (b.getHours())+':';
    }
    if(min<10){
        min = '0'+ (b.getMinutes())+':';
    }else {
        min = (b.getMinutes())+':';
    }
    if(second<10){
        second = '0'+ (b.getSeconds());
    }
    var str = String(year)+String(month)+String(date)+ ' '+String(hour)+String(min)+String(second);
    return str
}
 // 给字符串左边自动填补0
let preZeroFill = function (num, size) {
  if (num >= Math.pow(10, size)) {
    return num.toString();
  } else {
    var _str = Array(size + 1).join('0') + num;
    return _str.slice(_str.length - size);
  }
};
// 格式化UTC时间
util.dateFormat = function (time, format = 'Y-m-d H:i:s', zone = 0) {
  if (time == 0) {
    return '--:--:--'
  }
  var date = new Date(parseInt(time + '000'))

  if (zone !== 0) {
    var Y = date.getFullYear()
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
    var D = preZeroFill(date.getDate(), 2)
    var h = preZeroFill(date.getHours(), 2)
    var m = preZeroFill(date.getMinutes(), 2)
    var s = preZeroFill(date.getSeconds(), 2)
  } else {
    var Y = date.getUTCFullYear()
    var month = date.getUTCMonth() + 1
    var M = (month < 10 ? '0' + month : month)
    var D = preZeroFill(date.getUTCDate(), 2)
    var h = preZeroFill(date.getUTCHours(), 2)
    var m = preZeroFill(date.getUTCMinutes(), 2)
    var s = preZeroFill(date.getUTCSeconds(), 2)
  }

  var time = '';

  switch (format) {
    case 'Y-m-d':
      time = Y + "-" + M + "-" + D;
      break;
    case 'H:i:s':
      time = h + ":" + m + ":" + s;
      break;
    case 'YmdHis':
      time = Y + M + D + h + m + s;
      break;
    case 'Ymd':
      time = Y + M + D;
      break;
    case 'His':
      time = h + m + s;
      break;
    case 'Y/m/d':
      time = Y + "/" + M + "/" + D;
      break;
    default:
      time = Y + "-" + M + "-" + D + ' ' + h + ":" + m + ":" + s;
  }

  return time
};

// 标准时间转化为年月日
util.dateSingle = function (time) {
  return time.getFullYear() + '' + preZeroFill((time.getMonth() + 1), 2) + '' + preZeroFill(time.getDate(), 2)
}

// 值转换
util.handleValueDisplay = function (arr, key) {
  const target = arr ? arr.find(item => item.key == key) : ''
  return target ? target.value : '-'
}
util.indexOfJson = function (obj, value, key = '') {
    var k

    if (key) {
      for (k in obj) {
        if (obj[k][key] === value) {
          return k
        }
      }
    } else {
      for (k in obj) {
        if (obj[k] === value) {
          return k
        }
      }
    }

    return -1
  };
  util.getFileMD5 = function(file, callback) {
    var fileReader = new FileReader(),
    chunkSize = 2097152,
    chunks = Math.ceil(file.size / chunkSize),
    currentChunk = 0,
    spark = new SparkMD5()
    fileReader.onload = function(e) {
      spark.appendBinary(e.target.result)
      currentChunk++
      if (currentChunk < chunks) {
          loadNext()
      } else {
          callback(spark.end())
      }
    }
    // 处理单片文件的上传
    function loadNext() {
      var start = currentChunk * chunkSize,
          end = start + chunkSize >= file.size ? file.size : start + chunkSize
      fileReader.readAsBinaryString(file.slice(start, end))
    }
    loadNext()
  }
  // 整形转ip地址
  util.toIP = function _int2iP(num) {
    var str;
    var tt = new Array();
    tt[0] = (num >>> 24) >>> 0;
    tt[1] = ((num << 8) >>> 24) >>> 0;
    tt[2] = (num << 16) >>> 24;
    tt[3] = (num << 24) >>> 24;
    str = String(tt[0]) + "." + String(tt[1]) + "." + String(tt[2]) + "." + String(tt[3]);
    return str;
  }

export default util
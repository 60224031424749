// 设置api
let api = '/admin/v1/'
// let s3 = '/v3/cloud/'

export default {
    // S3文件上传
    S3push: `${api}file/push_sign`,
    // S3下载
    S3down: `${api}file/download_sign`,
    // 用户菜单
    AdminMenu: `${api}admin_menu_role/adminer_menu`,

    LogOut: `${api}login/out`,
    // 用户
    UserList: `${api}adminer/list`,
    UserInfo: `${api}adminer/detail`,
    UserUpdate: `${api}adminer/update`,
    UserDelete: `${api}adminer/delete/`,
    UserDetail: `${api}adminer/detail`,
    // 语言
    LanguageUpdate: `${api}language/update`,
    LanguageList: `${api}language/list`,
    LanguageDelete: `${api}language/delete/`,
    LanguageDetail: `${api}language/detail`,
    // 多语言管理
    MultiLanguageUpdate: `${api}app_pages/update`,
    MultiLanguageList: `${api}app_pages/list`,
    MultiLanguageDelete: `${api}app_pages/delete/`,
    MultiLanguageDetail: `${api}app_pages/detail`,
    // 页面元素
    ElementUpdate: `${api}app_pages/element_update`,
    ElementList: `${api}app_pages/element_list`,
    ElementDelete: `${api}app_pages/element_delete/`,
    ElementDetail: `${api}app_pages/element_detail`,
    // 元素翻译
    ElementTransUpdate: `${api}app_pages/element_language_update`,
    ElementTransList: `${api}app_pages/element_language_list`,
    ElementTransDelete: `${api}app_pages/element_language_delete/`,
    ElementTransDetail: `${api}app_pages/element_language_detail`,
    // 币种
    CurrencyUpdate: `${api}currency/update`,
    CurrencyList: `${api}currency/list`,
    CurrencyDelete: `${api}currency/delete/`,
    CurrencyDetail: `${api}currency/detail`,
    // 国家
    CountryUpdate: `${api}country/update`,
    CountryList: `${api}country/list`,
    CountryDelete: `${api}country/delete/`,
    CountryDetail: `${api}country/detail`,
    // 内容管理
    ContentUpdate: `${api}content/update`,
    ContentList: `${api}content/list`,
    ContentDelete: `${api}content/delete/`,
    ContentDetail: `${api}content/detail`,
    // 内容规则
    ContentRuleUpdate: `${api}content/rule_update`,
    ContentRuleList: `${api}content/rule_list`,
    ContentRuleDelete: `${api}content/rule_delete/`,
    ContentRuleDetail: `${api}content/rule_detail`,
    ContentRuleOrder: `${api}content/rule_change_order`,
    // 内容页
    RulePageUpdate: `${api}content/rule_page_update`,
    RulePageList: `${api}content/rule_page_list`,
    RulePageDelete: `${api}content/rule_page_delete/`,
    RulePageDetail: `${api}content/rule_page_detail`,
    RulePageSort: `${api}content/rule_page_change_order`,
    // 菜单
    MenuUpdate: `${api}admin_menu/update`,
    MenuList: `${api}admin_menu/list`,
    MenuDelete: `${api}admin_menu/delete/`,
    MenuDetail: `${api}admin_menu/detail`,
    MenuSort: `${api}admin_menu/change_order`,
    // 功能
    FuncUpdate: `${api}admin_page_function/update`,
    FuncList: `${api}admin_page_function/list`,
    FuncDelete: `${api}admin_page_function/delete/`,
    FuncDetail: `${api}admin_page_function/detail`,
    FuncSort: `${api}admin_page_function/change_order`,
    // 角色分配菜单
    MenuToRoleUpdate: `${api}admin_menu_role/update`,
    MenuToRoleList: `${api}admin_menu_role/list`,
    MenuToRoleDelete: `${api}admin_menu_role/delete/`,
    MenuToFuncAll: `${api}admin_menu_role/menu_func_list`,
    MenuToFuncRoleUpdate: `${api}admin_menu_role/function_update`,
    MenuToFuncRoleDelete: `${api}admin_menu_role/function_delete`,
    // 角色
    RoleUpdate: `${api}role/update`,
    RoleList: `${api}role/list`,
    RoleDelete: `${api}role/delete/`,
    RoleDetail: `${api}role/detail`,
    // 管理员分配角色
    AdminRoleUpdate: `${api}adminer_role/update`,
    AdminRoleList: `${api}adminer_role/list`,
    AdminRoleDelete: `${api}adminer_role/delete/`,
    // 分配区域
    AdminRegionUpdate: `${api}adminer_region/update`,
    AdminRegionList: `${api}adminer_region/list`,
    AdminRegionDelete: `${api}adminer_region/delete/`,
    // app管理
    ClientUpdate: `${api}client_app/update`,
    ClientList: `${api}client_app/list`,
    ClientDelete: `${api}client_app/delete/`,
    ClientDetail: `${api}client_app/detail`,
    // apns推送
    ApnsUpdate: `${api}app_apns/update`,
    ApnsList: `${api}app_apns/list`,
    ApnsDelete: `${api}app_apns/delete/`,
    ApnsDetail: `${api}app_apns/detail`,
    // Fcm推送
    FcmUpdate: `${api}app_fcm/update`,
    FcmList: `${api}app_fcm/list`,
    FcmDelete: `${api}app_fcm/delete/`,
    FcmDetail: `${api}app_fcm/detail`,
    // getui
    GetuiUpdate: `${api}app_getui/update`,
    GetuiList: `${api}app_getui/list`,
    GetuiDelete: `${api}app_getui/delete/`,
    GetuiDetail: `${api}app_getui/detail`,
    // 极光推送
    JpushUpdate: `${api}app_jpush/update`,
    JpushList: `${api}app_jpush/list`,
    JpushDelete: `${api}app_jpush/delete/`,
    JpushDetail: `${api}app_jpush/detail`,
    // 友盟
    UpushUpdate: `${api}app_upush/update`,
    UpushList: `${api}app_upush/list`,
    UpushDelete: `${api}app_upush/delete/`,
    UpushDetail: `${api}app_upush/detail`,
    // 产品
    ProductUpdate: `${api}client_product/update`,
    ProductList: `${api}client_product/list`,
    ProductDelete: `${api}client_product/delete/`,
    ProductDetail: `${api}client_product/detail`,
    // 产品型号
    ProductModelUpdate: `${api}client_product_model/update`,
    ProductModelList: `${api}client_product_model/list`,
    ProductModelDelete: `${api}client_product_model/delete/`,
    ProductModelDetail: `${api}client_product_model/detail`,
    ProductModelAttributes: `${api}client_product_model/attributes`,
    ProductModelAttributesAssign: `${api}client_product_model/attribute/assign`,
    ProductModelAttributesDefault: `${api}client_product_model/attribute/default`,
    // 型号配置
    ProductModelconfigeUpdate: `${api}client_product_model_config_v1/update`,
    ProductModelconfigeDelete: `${api}client_product_model_config_v1/delete/`,
    ProductModelconfigeDetail: `${api}client_product_model_config_v1/detail`,
    Productconfig: `${api}client_product_model/set_product`, // 取消/分配产品型号

    // 固件版本
    FirmwareUpdate: `${api}firmware_version/update`,
    FirmwareList: `${api}firmware_version/list`,
    FirmwareDelete: `${api}firmware_version/delete/`,
    FirmwareDetail: `${api}firmware_version/detail`,
    FirmwareDistribute: `${api}version_app/distribute`, // 取消/分配固件包
    FirmwarePreSign: `${api}file/version_compatible/push_sign`, // 固件上传预签名
    FirmwareDownSign: `${api}file/version_compatible/download_sign`, // 固件下载预签名

    // 软件版本
    SoftUpdate: `${api}version_app/update`,
    SoftList: `${api}version_app/list`,
    SoftDelete: `${api}version_app/delete/`,
    SoftDetail: `${api}version_app/detail`,
    // 强制升级
    forceUpgradeList: `${api}softversion_forceupgrade/list`,
    forceUpgradeDetail: `${api}softversion_forceupgrade/detail`,
    forceUpgradUpdate: `${api}softversion_forceupgrade/update`,
    forceUpgradDelete: `${api}softversion_forceupgrade/delete`,
    // 设备出厂
    DeliveryUpdate: `${api}client_device/update`,
    DeliveryList: `${api}client_device/list`,
    DeliveryDelete: `${api}client_device/delete/`,
    DeliveryDetail: `${api}client_device/detail`,
    // 客户授权
    ClientUserUpdate: `${api}client/update`,
    ClientUserList: `${api}client/list`,
    ClientUserDelete: `${api}client/delete/`,
    ClientUserDetail: `${api}client/detail`,
    ClientSecret: `${api}client/secret`,
    // 反馈类型
    FeedTypeUpdate: `${api}feedback_type/update`,
    FeedTypeList: `${api}feedback_type/list`,
    FeedTypeDelete: `${api}feedback_type/delete/`,
    FeedTypeDetail: `${api}feedback_type/detail`,
    FeedTypeOrder: `${api}feedback_type/change_order`,
    // 用户管理
    UserManageUpdate: `${api}migrage_user/update`,
    UserManageList: `${api}migrage_user/list`,
    UserManageDelete: `${api}migrage_user/delete/`,
    UserManageDetail: `${api}migrage_user/detail`,
    UserAppList: `${api}migrage_user/app_list`,
    UserAppStatus: `${api}migrage_user/change_status`,
    UserLoginList: `${api}migrage_user/login_list`,
    UserTrustDevList: `${api}migrage_user/trusted_devices_list`,
    DeleteAccountList: `${api}delete_account/list`,
    DeleteAccountCancel: `${api}delete_account/cancle`,
    // 用户绑定的涂鸦设备
    UserTuyaList: `${api}migrage_device/tuya_list`,
    UserTuyaLog: `${api}migrage_device/tuya_log`,

    // 用户设备分配
    UserDeviceUpdate: `${api}migrage_device/update`,
    UserDeviceList: `${api}migrage_device/list`,
    UserDeviceDelete: `${api}migrage_device/delete/`,
    // 套餐分配
    PackageCreate: `${api}set_meal/create`,
    PackageUpdate: `${api}set_meal/update`,
    PackageUpdatePrice: `${api}set_meal/update-price`,
    PackageList: `${api}set_meal/list`,
    PackageStatus: `${api}set_meal/change_status`,
    PackageChangeStatus: `${api}set_meal/status`,
    PackDelete: `${api}set_meal/delete/`,
    PackageDetail: `${api}set_meal/detail`,
    // 套餐折扣
    PackSaleUpdate: `${api}set_meal_discount/update`,
    PackSaleList: `${api}set_meal_discount/list`,
    PackSaleDelete: `${api}set_meal_discount/delete/`,
    PackSaleDetail: `${api}set_meal_discount/detail`,
    // 产品套餐分配
    ProPackDistriUpdate: `${api}set_meal_product_model/update`,
    ProPackDistriList: `${api}set_meal_product_model/list`,
    ProPackDistriDelete: `${api}set_meal_product_model/delete/`,
    // 优惠券
    CouponList: `${api}coupon/list`,
    CouponCreate: `${api}coupon/create`,

    // 密钥
    appjpush: `${api}app_jpush/secret`,
    appapnspush: `${api}app_apns/secret`,
    appgetuipush: `${api}app_getui/secret`,
    appumpush: `${api}app_upush/secret`,
    appfcmpush: `${api}app_fcm/secret`,
    clientapp: `${api}client_app/secret`,
    clientdevice: `${api}client_device/secret`,
    client: `${api}client/secret`,
    clientmodelSecret: `${api}client_product_model/secret`,
    // 区域配置
    ReginUpdate: `${api}region/update`,
    ReginList: `${api}region/list`,
    ReginDelete: `${api}region/delete/`,
    ReginDetail: `${api}region/detail`,
    // 套餐功能组
    PackFuncList: `${api}product_dp/list`,
    // 用户区域
    UserRegionInfo: `${api}adminer/myself`,
    // 邮件配置
    AppEmailUpdate: `${api}app_email/update`,
    AppEmailList: `${api}app_email/list`,
    AppEmailDelete: `${api}app_email/delete/`,
    AppEmailDetail: `${api}app_email/detail`,
    // 固件区域版本状态
    VersionStatusUpdate: `${api}firmware_version_status/update`,
    VersionStatusList: `${api}firmware_version_status/list`,
    VersionStatusDelete: `${api}firmware_version_status/delete/`,
    VersionStatusDetail: `${api}firmware_version_status/detail`,
    // 在线设备
    OnlineSecret: `${api}online_device/secret`,
    OnlineList: `${api}online_device/list`,
    OnlineDetail: `${api}online_device/detail`,
    DeleteBind: `${api}online_device/delete_user_device_bind`,
    OnlineFlowConfig: `${api}online_device/update_flow_config`,
    OnlineSatus: `${api}online_device/update_mqtt_online`,
    OnlineLogSatus: `${api}online_device/update_device_log_status`,
    OnlineCreateCloud: `${api}online_device/create_cloud_info`,
    OnlineCloudInfo: `${api}online_device/get_cloud_info`,
    ModifyUuid: `${api}online_device/notice_device_replace_uuid`,
    UpgradeDevice: `${api}online_device/notice_device_upgrade`,
    // 反馈列表
    FeedBackListList: `${api}feedback/list`,
    FeedBackListStatus: `${api}feedback/change_status`,
    FeedBackListDetail: `${api}feedback/detail`,
    // 消息模块
    SysMessageList: `${api}message/sys_message_list`,
    SysMessageDelete: `${api}message/del_all_sys_message`,
    SysMessageInfo: `${api}message/sys_message_detail`,
    SysMessageCreate: `${api}message/create_sys_message`,
    // 设备消息
    DevMessageList: `${api}message/device_message_list`,
    DevMessageDeleteTime: `${api}message/device_msg_time`,
    // 活动消息
    ActMessageList: `${api}message/activity_message_list`,
    ActMessageSendCount: `${api}message/activity/send/count`, // 获取活动消息推送人数
    // 下发消息
    SendMessage: `${api}message/sent_message`,
    SenderList: `${api}message/sender_list`,
    // 邮箱类型
    EmailTypeList: `${api}emailbox_type/list`,
    EmailTypeUpdate: `${api}emailbox_type/update`,
    EmailTypeDetail: `${api}emailbox_type/detail`,
    EmailTypeDelete: `${api}emailbox_type/delete`,
    // 发件人
    EmailSenderUpdate: `${api}emailbox_sender/update`,
    EmailSenderList: `${api}emailbox_sender/list`,
    EmailSenderDelete: `${api}emailbox_sender/delete`,
    EmailSenderDetail: `${api}emailbox_sender/detail`,
    // 发件人使用者
    EmailSenderUserList: `${api}emailbox_sender_user/list`,
    EmailSenderUserUpdate: `${api}emailbox_sender_user/update`,
    EmailSenderUserDelete: `${api}emailbox_sender_user/delete`,
    // app日志
    FileLogList: `${api}file/region/list_objects`,
    FileLogDown: `${api}file/region/download_sign`,
    FileLogListAnalyse: `${api}file/region/analyse_objects`,
    // 消息模板
    TemplateList: `${api}message_template_head/list`,
    TemplateUpdate: `${api}message_template_head/update`,
    TemplateDelete: `${api}message_template_head/delete`,
    TemplateDetail: `${api}message_template_head/detail`,
    // 邮件模板
    TemplateEmailList: `${api}message_template_email/list`,
    TemplateEmailUpdate: `${api}message_template_email/update`,
    TemplateEmailDelete: `${api}message_template_email/delete`,
    TemplateEmailDetail: `${api}message_template_email/detail`,
    // 图文消息
    TemplateImgList: `${api}message_template_image/list`,
    TemplateImgUpdate: `${api}message_template_image/update`,
    TemplateImgDelete: `${api}message_template_image/delete`,
    TemplateImgDetail: `${api}message_template_image/detail`,
    // 文本
    TemplateTextList: `${api}message_template_text/list`,
    TemplateTextUpdate: `${api}message_template_text/update`,
    TemplateTextDelete: `${api}message_template_text/delete`,
    TemplateTextDetail: `${api}message_template_text/detail`,
    // 消息类型
    TemplateTypeList: `${api}message_template_type/list`,
    TemplateTypeUpdate: `${api}message_template_type/update`,
    TemplateTypeDelete: `${api}message_template_type/delete`,
    TemplateTypeDetail: `${api}message_template_type/detail`,
    // 下发
    // TemplateTaskList: `${api}message_template_task/list`,
    // TemplateTaskUpdate: `${api}message_template_task/update`,
    // TemplateTaskDelete: `${api}message_template_task/delete`,
    // TemplateTaskDetail: `${api}message_template_task/detail`,
    TemplateTaskList: `${api}message_template_task/list`,
    TemplateTaskDelete: `${api}message_template_task/delete`,
    TemplateTaskCreate: `${api}message_template_task/create`,
    TemplateTaskCheck: `${api}message_template_task/check`,
    // 涂鸦设备
    DeviceTuyaList: `${api}migrage_device/tuya_device_list`,
    DeviceTuyaSync: `${api}migrage_device/sync_device`,
    // 类目管理
    CategoryTypeList: `${api}category/type_list`,
    CategoryTypeUpdate: `${api}category/type_update`,
    CategoryTypeDetail: `${api}category/type_detail`,
    CategoryTypeDelete: `${api}category/type_delete`,
    CategoryTypeOrder: `${api}category/type_change_order`,
    // 品类管理
    CategoryList: `${api}category/list`,
    CategoryUpdate: `${api}category/update`,
    CategoryDetail: `${api}category/detail`,
    CategoryDelete: `${api}category/delete`,
    CategoryOrder: `${api}category/change_order`,
    // app展示
    ClientAppShowList: `${api}client_app_show/list`,
    ClientAppShowUpdate: `${api}client_app_show/update`,
    ClientAppShowDelete: `${api}client_app_show/delete`,
    ClientAppShowDetail: `${api}client_app_show/detail`,
    ClientAppShowOrder: `${api}client_app_show/change_order`,
    // 品牌管理
    BrandList: `${api}client_brand/list`,
    BrandUpdate: `${api}client_brand/update`,
    BrandDelete: `${api}client_brand/delete`,
    BrandDetail: `${api}client_brand/detail`,
    // app展示品牌
    BrandAppList: `${api}client_brand_app/list`,
    BrandAppUpdate: `${api}client_brand_app/update`,
    BrandAppDelete: `${api}client_brand_app/delete`,
    // 面板管理
    PanelList: `${api}category_panel/list`,
    PanelUpdate: `${api}category_panel/update`,
    PanelDelete: `${api}category_panel/delete`,
    PanelDetail: `${api}category_panel/detail`,
    // 配网管理
    SetNetworkList: `${api}set_network/list`,
    SetNetworkUpdate: `${api}set_network/update`,
    SetNetworkDelete: `${api}set_network/delete`,
    SetNetworkDetail: `${api}set_network/detail`,
    // 当前用户信息
    AdminerSelfInfo: `${api}adminer/myself`,
    AdminerSelfUpdate: `${api}adminer/myself_update`,
    // bannel
    BannelList: `${api}content/rule_list_bannel`,
    // 订单管理
    OrderList: `${api}order/list`,
    OrderInfo: `${api}order/info`,
    OrderSubscribe: `${api}order/subscribe_list`,
    OrderSubscribeInfo: `${api}order/subscribe_info`,
    OrderCloudInfo: `${api}order/cloud_info`,
    // 内容管理状态修改
    ContentStatus: `${api}content/change_status`,
    RuleStatus: `${api}content/rule_change_status`,
    RulePageStatus: `${api}content/rule_page_change_status`,
    // alexa 类型 列表
    AlexaCategoryList: `${api}client_product_model/alexa_category`,
    // 社媒列表
    BrandMediaList: `${api}client_brand_media/list`,
    BrandMediaUpdate: `${api}client_brand_media/update`,
    BrandMediaDelete: `${api}client_brand_media/delete`,
    BrandMediaDetail: `${api}client_brand_media/detail`,
    // 统计看板
    BoardViewUser: `${api}board_view/nooie_user`,
    BoardViewUser_Day: `${api}board_view/nooie_user_by_day`,
    BoardViewUser_Subscription: `${api}board_view/nooie_subscription`,
    BoardViewUser_Revenue: `${api}board_view/nooie_order_amount`,
    BoardViewUser_Revenue_Day: `${api}board_view/nooie_order_amount_by_day`,
    BoardViewUser_device: `${api}board_view/nooie_device`,
    BoardViewUser_device_sub: `${api}board_view/nooie_subscription_device`,
    BoardViewUser_device_Day: `${api}board_view/nooie_device_by_day`,
    BoardViewUser_camera: `${api}board_view/nooie_model_device`,
    BoardViewUser_camera_sub: `${api}board_view/nooie_subscription_model_device`,
    BoardViewUser_camera_Day: `${api}board_view/nooie_model_device_by_day`,
    // 云存储订阅
    CancelCloudRenew: `${api}order/unsubscribe`,
    CloseCloud: `${api}order/shutdown_cloud`,

    // Rn 面板
    RnPanelTypes: `${api}panel/types`,
    RnPanelTypeCreate: `${api}panel/type`,
    RnPanelTypeDistribute: `${api}panel/type/distribute`,
    RnPanelTypeModels: `${api}panel/type/models`,
    RnPanelVersions: `${api}panel/versions`,
    RnPanelVersionInfo: `${api}panel/version/info`,
    RnPanelUploadUrl: `${api}panel/version`,
    RnPanelUpdateStatus: `${api}panel/version-status`,

    // 物模型
    TslAttributeCreate: `${api}tsl/attribute/add`,
    TslAttributeList: `${api}tsl/attribute/list`,
    TslAttributeDelete: `${api}tsl/attribute/delete`,

    // 质保计划的亚马逊订单查询
    WarrantyOrderList: `${api}warranty/order/list`,
    WarrantyOrderUpdate: `${api}warranty/order/update`, // 质保计划的亚马逊订单备注修改

    // 邀评链接
    InviteCommentCreate: `${api}invite/comment/create`,
    InviteCommentList: `${api}invite/comment/list`,
    InviteCommentUpdate: `${api}invite/comment/update`,
    InviteCommentDelete: `${api}invite/comment/delete`
}

import { fetch } from './fetch'
import api from './url'

export default {
    // 用户区域列表
    UserRegionInfo: function(params) {
        return fetch({
            url: api.UserRegionInfo,
            method: 'get',
            params: params
        })
    },
    // S3上传
    S3push: function(params) {
        return fetch({
            url: api.S3push,
            method: 'post',
            data: params
        })
    },
    S3down: function(params) {
        return fetch({
            url: api.S3down,
            method: 'get',
            params: params
        })
    },
    // 用户菜单
    AdminMenu: function(params) {
        return fetch({
            url: api.AdminMenu,
            method: 'get',
            params: params
        })
    },

    LogOut: function() {
        return fetch({
            url: api.LogOut,
            method: 'get'
        })
    },
    // 用户
    Userlist: function(params) {
        return fetch({
            url: api.UserList,
            method: 'get',
            params: params
        })
    },
    UserDetail: function(params) {
        return fetch({
            url: api.UserDetail,
            method: 'get',
            params: params
        })
    },
    UserInfo: function(params) {
        return fetch({
            url: api.UserInfo,
            method: 'get',
            params: params
        })
    },
    UserUpdate: function(params) {
        return fetch({
            url: api.UserUpdate,
            method: 'post',
            data: params
        })
    },
    UserDelete: function(params) {
        return fetch({
            url: api.UserDelete,
            method: 'post',
            data: params
        })
    },
    // 语言
    LanguageUpdate: function(params) {
        return fetch({
            url: api.LanguageUpdate,
            method: 'post',
            data: params
        })
    },
    LanguageList: function(params) {
        return fetch({
            url: api.LanguageList,
            method: 'get',
            params: params
        })
    },
    LanguageDelete: function(params) {
        return fetch({
            url: api.LanguageDelete,
            method: 'post',
            data: params
        })
    },
    LanguageDetail: function(params) {
        return fetch({
            url: api.LanguageDetail,
            method: 'get',
            params: params
        })
    },
    // 多语言管理
    MultiLanguageUpdate: function(params) {
        return fetch({
            url: api.MultiLanguageUpdate,
            method: 'post',
            data: params
        })
    },
    MultiLanguageList: function(params) {
        return fetch({
            url: api.MultiLanguageList,
            method: 'get',
            params: params
        })
    },
    MultiLanguageDelete: function(params) {
        return fetch({
            url: api.MultiLanguageDelete,
            method: 'post',
            data: params
        })
    },
    MultiLanguageDetail: function(params) {
        return fetch({
            url: api.MultiLanguageDetail,
            method: 'get',
            params: params
        })
    },
    // 页面元素
    ElementUpdate: function(params) {
        return fetch({
            url: api.ElementUpdate,
            method: 'post',
            data: params
        })
    },
    ElementList: function(params) {
        return fetch({
            url: api.ElementList,
            method: 'get',
            params: params
        })
    },
    ElementDelete: function(params) {
        return fetch({
            url: api.ElementDelete,
            method: 'post',
            data: params
        })
    },
    ElementDetail: function(params) {
        return fetch({
            url: api.ElementDetail,
            method: 'get',
            params: params
        })
    },
    // 元素翻译
    ElementTransUpdate: function(params) {
        return fetch({
            url: api.ElementTransUpdate,
            method: 'post',
            data: params
        })
    },
    ElementTransList: function(params) {
        return fetch({
            url: api.ElementTransList,
            method: 'get',
            params: params
        })
    },
    ElementTransDelete: function(params) {
        return fetch({
            url: api.ElementTransDelete,
            method: 'post',
            data: params
        })
    },
    ElementTransDetail: function(params) {
        return fetch({
            url: api.ElementTransDetail,
            method: 'get',
            params: params
        })
    },
    // 币种
    CurrencyUpdate: function(params) {
        return fetch({
            url: api.CurrencyUpdate,
            method: 'post',
            data: params
        })
    },
    CurrencyList: function(params) {
        return fetch({
            url: api.CurrencyList,
            method: 'get',
            params: params
        })
    },
    CurrencyDelete: function(params) {
        return fetch({
            url: api.CurrencyDelete,
            method: 'post',
            data: params
        })
    },
    CurrencyDetail: function(params) {
        return fetch({
            url: api.CurrencyDetail,
            method: 'get',
            params: params
        })
    },
    // 国家
    CountryUpdate: function(params) {
        return fetch({
            url: api.CountryUpdate,
            method: 'post',
            data: params
        })
    },
    CountryList: function(params) {
        return fetch({
            url: api.CountryList,
            method: 'get',
            params: params
        })
    },
    CountryDelete: function(params) {
        return fetch({
            url: api.CountryDelete,
            method: 'post',
            data: params
        })
    },
    CountryDetail: function(params) {
        return fetch({
            url: api.CountryDetail,
            method: 'get',
            params: params
        })
    },
    // 内容管理
    ContentUpdate: function(params) {
        return fetch({
            url: api.ContentUpdate,
            method: 'post',
            data: params
        })
    },
    ContentList: function(params) {
        return fetch({
            url: api.ContentList,
            method: 'get',
            params: params
        })
    },
    ContentDelete: function(params) {
        return fetch({
            url: api.ContentDelete,
            method: 'post',
            data: params
        })
    },
    ContentDetail: function(params) {
        return fetch({
            url: api.ContentDetail,
            method: 'get',
            params: params
        })
    },
    // 内容规则
    ContentRuleUpdate: function(params) {
        return fetch({
            url: api.ContentRuleUpdate,
            method: 'post',
            data: params
        })
    },
    ContentRuleList: function(params) {
        return fetch({
            url: api.ContentRuleList,
            method: 'get',
            params: params
        })
    },
    ContentRuleDelete: function(params) {
        return fetch({
            url: api.ContentRuleDelete,
            method: 'post',
            data: params
        })
    },
    ContentRuleDetail: function(params) {
        return fetch({
            url: api.ContentRuleDetail,
            method: 'get',
            params: params
        })
    },
    ContentRuleOrder: function(params) {
        return fetch({
            url: api.ContentRuleOrder,
            method: 'post',
            data: params
        })
    },
    // 内容页
    RulePageUpdate: function(params) {
        return fetch({
            url: api.RulePageUpdate,
            method: 'post',
            data: params
        })
    },
    RulePageList: function(params) {
        return fetch({
            url: api.RulePageList,
            method: 'get',
            params: params
        })
    },
    RulePageDelete: function(params) {
        return fetch({
            url: api.RulePageDelete,
            method: 'post',
            data: params
        })
    },
    RulePageDetail: function(params) {
        return fetch({
            url: api.RulePageDetail,
            method: 'get',
            params: params
        })
    },
    RulePageSort: function(params) {
        return fetch({
            url: api.RulePageSort,
            method: 'post',
            data: params
        })
    },
    // 菜单
    MenuUpdate: function(params) {
        return fetch({
            url: api.MenuUpdate,
            method: 'post',
            data: params
        })
    },
    MenuList: function(params) {
        return fetch({
            url: api.MenuList,
            method: 'get',
            params: params
        })
    },
    MenuDelete: function(params) {
        return fetch({
            url: api.MenuDelete,
            method: 'post',
            data: params
        })
    },
    MenuDetail: function(params) {
        return fetch({
            url: api.MenuDetail,
            method: 'get',
            params: params
        })
    },
    MenuSort: function(params) {
        return fetch({
            url: api.MenuSort,
            method: 'post',
            data: params
        })
    },
    // 功能
    FuncUpdate: function(params) {
        return fetch({
            url: api.FuncUpdate,
            method: 'post',
            data: params
        })
    },
    FuncList: function(params) {
        return fetch({
            url: api.FuncList,
            method: 'get',
            params: params
        })
    },
    FuncDelete: function(params) {
        return fetch({
            url: api.FuncDelete,
            method: 'post',
            data: params
        })
    },
    FuncDetail: function(params) {
        return fetch({
            url: api.FuncDetail,
            method: 'get',
            params: params
        })
    },
    FuncSort: function(params) {
        return fetch({
            url: api.FuncSort,
            method: 'post',
            data: params
        })
    },
    // 角色分配菜单
    MenuToRoleUpdate: function(params) {
        return fetch({
            url: api.MenuToRoleUpdate,
            method: 'post',
            data: params
        })
    },
    MenuToRoleList: function(params) {
        return fetch({
            url: api.MenuToRoleList,
            method: 'get',
            params: params
        })
    },
    MenuToRoleDelete: function(params) {
        return fetch({
            url: api.MenuToRoleDelete,
            method: 'post',
            data: params
        })
    },
    MenuToFuncAll: function(params) {
        return fetch({
            url: api.MenuToFuncAll,
            method: 'get',
            params: params
        })
    },
    MenuToFuncRoleUpdate: function(params) {
        return fetch({
            url: api.MenuToFuncRoleUpdate,
            method: 'post',
            data: params
        })
    },
    MenuToFuncRoleDelete: function(params) {
        return fetch({
            url: api.MenuToFuncRoleDelete,
            method: 'post',
            data: params
        })
    },
    // 角色
    RoleUpdate: function(params) {
        return fetch({
            url: api.RoleUpdate,
            method: 'post',
            data: params
        })
    },
    RoleList: function(params) {
        return fetch({
            url: api.RoleList,
            method: 'get',
            params: params
        })
    },
    RoleDelete: function(params) {
        return fetch({
            url: api.RoleDelete,
            method: 'post',
            data: params
        })
    },
    RoleDetail: function(params) {
        return fetch({
            url: api.RoleDetail,
            method: 'get',
            params: params
        })
    },
    // 管理员分配角色
    AdminRoleUpdate: function(params) {
        return fetch({
            url: api.AdminRoleUpdate,
            method: 'post',
            data: params
        })
    },
    AdminRoleList: function(params) {
        return fetch({
            url: api.AdminRoleList,
            method: 'get',
            params: params
        })
    },
    AdminRoleDelete: function(params) {
        return fetch({
            url: api.AdminRoleDelete,
            method: 'post',
            data: params
        })
    },
    // 分配区域
    AdminRegionUpdate: function(params) {
        return fetch({
            url: api.AdminRegionUpdate,
            method: 'post',
            data: params
        })
    },
    AdminRegionList: function(params) {
        return fetch({
            url: api.AdminRegionList,
            method: 'get',
            params: params
        })
    },
    AdminRegionDelete: function(params) {
        return fetch({
            url: api.AdminRegionDelete,
            method: 'post',
            data: params
        })
    },
    // app管理
    ClientUpdate: function(params) {
        return fetch({
            url: api.ClientUpdate,
            method: 'post',
            data: params
        })
    },
    ClientList: function(params) {
        return fetch({
            url: api.ClientList,
            method: 'get',
            params: params
        })
    },
    ClientDelete: function(params) {
        return fetch({
            url: api.ClientDelete,
            method: 'post',
            data: params
        })
    },
    ClientDetail: function(params) {
        return fetch({
            url: api.ClientDetail,
            method: 'get',
            params: params
        })
    },
    // apns推送
    ApnsUpdate: function(params) {
        return fetch({
            url: api.ApnsUpdate,
            method: 'post',
            data: params
        })
    },
    ApnsList: function(params) {
        return fetch({
            url: api.ApnsList,
            method: 'get',
            params: params
        })
    },
    ApnsDelete: function(params) {
        return fetch({
            url: api.ApnsDelete,
            method: 'post',
            data: params
        })
    },
    ApnsDetail: function(params) {
        return fetch({
            url: api.ApnsDetail,
            method: 'get',
            params: params
        })
    },
    // FCM推送
    FcmUpdate: function(params) {
        return fetch({
            url: api.FcmUpdate,
            method: 'post',
            data: params
        })
    },
    FcmList: function(params) {
        return fetch({
            url: api.FcmList,
            method: 'get',
            params: params
        })
    },
    FcmDelete: function(params) {
        return fetch({
            url: api.FcmDelete,
            method: 'post',
            data: params
        })
    },
    FcmDetail: function(params) {
        return fetch({
            url: api.FcmDetail,
            method: 'get',
            params: params
        })
    },
    // getui
    GetuiUpdate: function(params) {
        return fetch({
            url: api.GetuiUpdate,
            method: 'post',
            data: params
        })
    },
    GetuiList: function(params) {
        return fetch({
            url: api.GetuiList,
            method: 'get',
            params: params
        })
    },
    GetuiDelete: function(params) {
        return fetch({
            url: api.GetuiDelete,
            method: 'post',
            data: params
        })
    },
    GetuiDetail: function(params) {
        return fetch({
            url: api.GetuiDetail,
            method: 'get',
            params: params
        })
    },
    // 极光
    JpushUpdate: function(params) {
        return fetch({
            url: api.JpushUpdate,
            method: 'post',
            data: params
        })
    },
    JpushList: function(params) {
        return fetch({
            url: api.JpushList,
            method: 'get',
            params: params
        })
    },
    JpushDelete: function(params) {
        return fetch({
            url: api.JpushDelete,
            method: 'post',
            data: params
        })
    },
    JpushDetail: function(params) {
        return fetch({
            url: api.JpushDetail,
            method: 'get',
            params: params
        })
    },
    // 友盟
    UpushUpdate: function(params) {
        return fetch({
            url: api.UpushUpdate,
            method: 'post',
            data: params
        })
    },
    UpushList: function(params) {
        return fetch({
            url: api.UpushList,
            method: 'get',
            params: params
        })
    },
    UpushDelete: function(params) {
        return fetch({
            url: api.UpushDelete,
            method: 'post',
            data: params
        })
    },
    UpushDetail: function(params) {
        return fetch({
            url: api.UpushDetail,
            method: 'get',
            params: params
        })
    },
    // 产品
    ProductUpdate: function(params) {
        return fetch({
            url: api.ProductUpdate,
            method: 'post',
            data: params
        })
    },
    ProductList: function(params) {
        return fetch({
            url: api.ProductList,
            method: 'get',
            params: params
        })
    },
    ProductDelete: function(params) {
        return fetch({
            url: api.ProductDelete,
            method: 'post',
            data: params
        })
    },
    ProductDetail: function(params) {
        return fetch({
            url: api.ProductDetail,
            method: 'get',
            params: params
        })
    },
    // 产品型号
    ProductModelUpdate: function(params) {
        return fetch({
            url: api.ProductModelUpdate,
            method: 'post',
            data: params
        })
    },
    ProductModelList: function(params) {
        return fetch({
            url: api.ProductModelList,
            method: 'get',
            params: params
        })
    },
    ProductModelDelete: function(params) {
        return fetch({
            url: api.ProductModelDelete,
            method: 'post',
            data: params
        })
    },
    ProductModelDetail: function(params) {
        return fetch({
            url: api.ProductModelDetail,
            method: 'get',
            params: params
        })
    },
    ProductModelAttributes: function(params) {
        return fetch({
            url: api.ProductModelAttributes,
            method: 'get',
            params: params
        })
    },
    ProductModelAttributesAssign: function(params) {
        return fetch({
            url: api.ProductModelAttributesAssign,
            method: 'post',
            data: params
        })
    },
    ProductModelAttributesDefault: function(params) {
        return fetch({
            url: api.ProductModelAttributesDefault,
            method: 'post',
            data: params
        })
    },
    // 产品型号配置
    ProductModelconfigeUpdate: function(params) {
        return fetch({
            url: api.ProductModelconfigeUpdate,
            method: 'post',
            data: params
        })
    },
    ProductModelconfigeDelete: function(params) {
        return fetch({
            url: api.ProductModelconfigeDelete,
            method: 'post',
            data: params
        })
    },
    ProductModelconfigeDetail: function(params) {
        return fetch({
            url: api.ProductModelconfigeDetail,
            method: 'get',
            params: params
        })
    },
    // 取消/分配产品型号
    Productconfig: function(params) {
        return fetch({
            url: api.Productconfig,
            method: 'post',
            data: params
        })
    },
    // 固件版本
    FirmwareUpdate: function(params) {
        return fetch({
            url: api.FirmwareUpdate,
            method: 'post',
            data: params
        })
    },
    FirmwareList: function(params) {
        return fetch({
            url: api.FirmwareList,
            method: 'get',
            params: params
        })
    },
    FirmwareDelete: function(params) {
        return fetch({
            url: api.FirmwareDelete,
            method: 'post',
            data: params
        })
    },
    FirmwareDetail: function(params) {
        return fetch({
            url: api.FirmwareDetail,
            method: 'get',
            params: params
        })
    },
    FirmwareDistribute: function(params) {
        return fetch({
            url: api.FirmwareDistribute,
            method: 'post',
            data: params
        })
    },
    FirmwarePreSign: function(params) {
        return fetch({
            url: api.FirmwarePreSign,
            method: 'post',
            data: params
        })
    },
    FirmwareDownSign: function(params) {
        return fetch({
            url: api.FirmwareDownSign,
            method: 'get',
            params: params
        })
    },
    // 软件版本
    SoftUpdate: function(params) {
        return fetch({
            url: api.SoftUpdate,
            method: 'post',
            data: params
        })
    },
    SoftList: function(params) {
        return fetch({
            url: api.SoftList,
            method: 'get',
            params: params
        })
    },
    SoftDelete: function(params) {
        return fetch({
            url: api.SoftDelete,
            method: 'post',
            data: params
        })
    },
    SoftDetail: function(params) {
        return fetch({
            url: api.SoftDetail,
            method: 'get',
            params: params
        })
    },
    // 强制升级
    ForceUpgradeList: function(params) {
        return fetch({
            url: api.forceUpgradeList,
            method: 'get',
            params: params
        })
    },
    ForceUpgradeDetail: function(params) {
        return fetch({
            url: api.forceUpgradeDetail,
            method: 'get',
            params: params
        })
    },
    ForceUpgradUpdate: function(params) {
        return fetch({
            url: api.forceUpgradUpdate,
            method: 'post',
            data: params
        })
    },
    ForceUpgradDelete: function(params) {
        return fetch({
            url: api.forceUpgradDelete,
            method: 'post',
            data: params
        })
    },
    // 固件版本区域状态
    VersionStatusUpdate: function(params) {
        return fetch({
            url: api.VersionStatusUpdate,
            method: 'post',
            data: params
        })
    },
    VersionStatusList: function(params) {
        return fetch({
            url: api.VersionStatusList,
            method: 'get',
            params: params
        })
    },
    VersionStatusDelete: function(params) {
        return fetch({
            url: api.VersionStatusDelete,
            method: 'post',
            data: params
        })
    },
    VersionStatusDetail: function(params) {
        return fetch({
            url: api.VersionStatusDetail,
            method: 'get',
            params: params
        })
    },
    // 设备出厂
    DeliveryUpdate: function(params) {
        return fetch({
            url: api.DeliveryUpdate,
            method: 'post',
            data: params
        })
    },
    DeliveryList: function(params) {
        return fetch({
            url: api.DeliveryList,
            method: 'get',
            params: params
        })
    },
    DeliveryDelete: function(params) {
        return fetch({
            url: api.DeliveryDelete,
            method: 'post',
            data: params
        })
    },
    DeliveryDetail: function(params) {
        return fetch({
            url: api.DeliveryDetail,
            method: 'get',
            params: params
        })
    },
    // 客户授权
    ClientUserUpdate: function(params) {
        return fetch({
            url: api.ClientUserUpdate,
            method: 'post',
            data: params
        })
    },
    ClientUserList: function(params) {
        return fetch({
            url: api.ClientUserList,
            method: 'get',
            params: params
        })
    },
    ClientUserDelete: function(params) {
        return fetch({
            url: api.ClientUserDelete,
            method: 'post',
            data: params
        })
    },
    ClientUserDetail: function(params) {
        return fetch({
            url: api.ClientUserDetail,
            method: 'get',
            params: params
        })
    },
    ClientSecret: function(params) {
        return fetch({
            url: api.ClientSecret,
            method: 'get',
            params: params
        })
    },
    // 反馈类型
    FeedTypeUpdate: function(params) {
        return fetch({
            url: api.FeedTypeUpdate,
            method: 'post',
            data: params
        })
    },
    FeedTypeList: function(params) {
        return fetch({
            url: api.FeedTypeList,
            method: 'get',
            params: params
        })
    },
    FeedTypeDelete: function(params) {
        return fetch({
            url: api.FeedTypeDelete,
            method: 'post',
            data: params
        })
    },
    FeedTypeDetail: function(params) {
        return fetch({
            url: api.FeedTypeDetail,
            method: 'get',
            params: params
        })
    },
    FeedTypeOrder: function(params) {
        return fetch({
            url: api.FeedTypeOrder,
            method: 'post',
            data: params
        })
    },
    // 用户管理
    UserManageUpdate: function(params) {
        return fetch({
            url: api.UserManageUpdate,
            method: 'post',
            data: params
        })
    },
    UserManageList: function(params) {
        return fetch({
            url: api.UserManageList,
            method: 'get',
            params: params
        })
    },
    UserManageDelete: function(params) {
        return fetch({
            url: api.UserManageDelete,
            method: 'post',
            data: params
        })
    },
    UserManageDetail: function(params) {
        return fetch({
            url: api.UserManageDetail,
            method: 'get',
            params: params
        })
    },
    DeleteAccountList: function(params) {
        return fetch({
            url: api.DeleteAccountList,
            method: 'get',
            params: params
        })
    },
    DeleteAccountCancel: function(data) {
        return fetch({
            url: api.DeleteAccountCancel,
            method: 'post',
            data
        })
    },
    UserAppList: function(params) {
        return fetch({
            url: api.UserAppList,
            method: 'get',
            params: params
        })
    },
    UserAppStatus: function(data, params) {
        return fetch({
            url: api.UserAppStatus,
            method: 'post',
            data: data,
            params: params
        })
    },
    UserLoginList: function(params) {
        return fetch({
            url: api.UserLoginList,
            method: 'get',
            params: params
        })
    },
    UserTrustDevList: function(params) {
        return fetch({
            url: api.UserTrustDevList,
            method: 'get',
            params: params
        })
    },
    // 用户设备分配
    UserDeviceUpdate: function(params) {
        return fetch({
            url: api.UserDeviceUpdate,
            method: 'post',
            data: params
        })
    },
    UserDeviceList: function(params) {
        return fetch({
            url: api.UserDeviceList,
            method: 'get',
            params: params
        })
    },
    UserDeviceDelete: function(params) {
        return fetch({
            url: api.UserDeviceDelete,
            method: 'post',
            data: params
        })
    },
    // 在线设备
    OnlineSecret: function(params) {
        return fetch({
            url: api.OnlineSecret,
            method: 'get',
            params: params
        })
    },
    OnlineList: function(params) {
        return fetch({
            url: api.OnlineList,
            method: 'get',
            params: params
        })
    },
    OnlineDetail: function(params) {
        return fetch({
            url: api.OnlineDetail,
            method: 'get',
            params: params
        })
    },
    DeleteBind: function(params) {
        return fetch({
            url: api.DeleteBind,
            method: 'post',
            data: params
        })
    },
    OnlineFlowConfig: function(params) {
        return fetch({
            url: api.OnlineFlowConfig,
            method: 'post',
            data: params
        })
    },
    OnlineSatus: function(params) {
        return fetch({
            url: api.OnlineSatus,
            method: 'post',
            data: params
        })
    },
    OnlineLogSatus: function(params) {
        return fetch({
            url: api.OnlineLogSatus,
            method: 'post',
            data: params
        })
    },
    OnlineCreateCloud: function(data, params) {
        return fetch({
            url: api.OnlineCreateCloud,
            method: 'post',
            data: data,
            params: params
        })
    },
    OnlineCloudInfo: function(params) {
        return fetch({
            url: api.OnlineCloudInfo,
            method: 'get',
            params: params
        })
    },
    ModifyUuid: function(params) {
        return fetch({
            url: api.ModifyUuid,
            method: 'POST',
            data: params
        })
    },
    UpgradeDevice: function(params) {
        return fetch({
            url: api.UpgradeDevice,
            method: 'POST',
            data: params
        })
    },
    // 反馈列表
    FeedBackListList: function(params) {
        return fetch({
            url: api.FeedBackListList,
            method: 'get',
            params: params
        })
    },
    FeedBackListStatus: function(params) {
        return fetch({
            url: api.FeedBackListStatus,
            method: 'post',
            data: params
        })
    },
    FeedBackListDetail: function(params) {
        return fetch({
            url: api.FeedBackListDetail,
            method: 'get',
            params: params
        })
    },
    // 消息模块
    SysMessageDelete: function(params) {
        return fetch({
            url: api.SysMessageDelete,
            method: 'post',
            data: params
        })
    },
    SysMessageCreate: function(params) {
        return fetch({
            url: api.SysMessageCreate,
            method: 'post',
            data: params
        })
    },
    SysMessageList: function(params) {
        return fetch({
            url: api.SysMessageList,
            method: 'get',
            params: params
        })
    },
    SysMessageInfo: function(params) {
        return fetch({
            url: api.SysMessageInfo,
            method: 'get',
            params: params
        })
    },
    // 设备消息
    DevMessageList: function(params) {
        return fetch({
            url: api.DevMessageList,
            method: 'get',
            params: params
        })
    },
    DevMessageDeleteTime: function(params) {
        return fetch({
            url: api.DevMessageDeleteTime,
            method: 'get',
            params: params
        })
    },
    // 活动消息
    ActMessageList: function(params) {
        return fetch({
            url: api.ActMessageList,
            method: 'get',
            params: params
        })
    },
    ActMessageSendCount: function(params) {
        return fetch({
            url: api.ActMessageSendCount,
            method: 'get',
            params: params
        })
    },
    // 下发消息
    SendMessage: function(params) {
        return fetch({
            url: api.SendMessage,
            method: 'post',
            data: params
        })
    },
    SenderList: function(params) {
        return fetch({
            url: api.SenderList,
            method: 'get',
            params: params
        })
    },
    EmailTypeList: function(params) {
        return fetch({
            url: api.EmailTypeList,
            method: 'get',
            params: params
        })
    },
    EmailTypeDetail: function(params) {
        return fetch({
            url: api.EmailTypeDetail,
            method: 'get',
            params: params
        })
    },
    EmailTypeUpdate: function(params) {
        return fetch({
            url: api.EmailTypeUpdate,
            method: 'post',
            data: params
        })
    },
    EmailTypeDelete: function(params) {
        return fetch({
            url: api.EmailTypeDelete,
            method: 'post',
            data: params
        })
    },
    // 用户绑定的涂鸦设备
    UserTuyaList: function(params) {
        return fetch({
            url: api.UserTuyaList,
            method: 'get',
            params: params
        })
    },
    UserTuyaLog: function(params) {
        return fetch({
            url: api.UserTuyaLog,
            method: 'get',
            params: params
        })
    },
    FileLogList: function(params) {
        return fetch({
            url: api.FileLogList,
            method: 'get',
            params: params
        })
    },
    FileLogListAnalyse: function(params) {
        return fetch({
            url: api.FileLogListAnalyse,
            method: 'get',
            params: params
        })
    },
    FileLogDown: function(params) {
        return fetch({
            url: api.FileLogDown,
            method: 'get',
            params: params
        })
    },
    // 发件人
    EmailSenderUpdate: function(params) {
        return fetch({
            url: api.EmailSenderUpdate,
            method: 'post',
            data: params
        })
    },
    EmailSenderList: function(params) {
        return fetch({
            url: api.EmailSenderList,
            method: 'get',
            params: params
        })
    },
    EmailSenderDelete: function(params) {
        return fetch({
            url: api.EmailSenderDelete,
            method: 'post',
            data: params
        })
    },
    EmailSenderDetail: function(params) {
        return fetch({
            url: api.EmailSenderDetail,
            method: 'get',
            params: params
        })
    },
    // 使用者
    EmailSenderUserList: function(params) {
        return fetch({
            url: api.EmailSenderUserList,
            method: 'get',
            params: params
        })
    },
    EmailSenderUserUpdate: function(params) {
        return fetch({
            url: api.EmailSenderUserUpdate,
            method: 'post',
            data: params
        })
    },
    EmailSenderUserDelete: function(params) {
        return fetch({
            url: api.EmailSenderUserDelete,
            method: 'post',
            data: params
        })
    },
    // 消息模板
    TemplateList: function(params) {
        return fetch({
            url: api.TemplateList,
            method: 'get',
            params: params
        })
    },
    TemplateUpdate: function(params) {
        return fetch({
            url: api.TemplateUpdate,
            method: 'post',
            data: params
        })
    },
    TemplateDelete: function(params) {
        return fetch({
            url: api.TemplateDelete,
            method: 'post',
            data: params
        })
    },
    TemplateDetail: function(params) {
        return fetch({
            url: api.TemplateDetail,
            method: 'get',
            params: params
        })
    },
    // 邮件消息
    TemplateEmailList: function(params) {
        return fetch({
            url: api.TemplateEmailList,
            method: 'get',
            params: params
        })
    },
    TemplateEmailUpdate: function(params) {
        return fetch({
            url: api.TemplateEmailUpdate,
            method: 'post',
            data: params
        })
    },
    TemplateEmailDelete: function(params) {
        return fetch({
            url: api.TemplateEmailDelete,
            method: 'post',
            data: params
        })
    },
    TemplateEmailDetail: function(params) {
        return fetch({
            url: api.TemplateEmailDetail,
            method: 'get',
            params: params
        })
    },
    // 图文消息
    TemplateImgList: function(params) {
        return fetch({
            url: api.TemplateImgList,
            method: 'get',
            params: params
        })
    },
    TemplateImgUpdate: function(params) {
        return fetch({
            url: api.TemplateImgUpdate,
            method: 'post',
            data: params
        })
    },
    TemplateImgDelete: function(params) {
        return fetch({
            url: api.TemplateImgDelete,
            method: 'post',
            data: params
        })
    },
    TemplateImgDetail: function(params) {
        return fetch({
            url: api.TemplateImgDetail,
            method: 'get',
            params: params
        })
    },
    // 文本消息
    TemplateTextList: function(params) {
        return fetch({
            url: api.TemplateTextList,
            method: 'get',
            params: params
        })
    },
    TemplateTextUpdate: function(params) {
        return fetch({
            url: api.TemplateTextUpdate,
            method: 'post',
            data: params
        })
    },
    TemplateTextDelete: function(params) {
        return fetch({
            url: api.TemplateTextDelete,
            method: 'post',
            data: params
        })
    },
    TemplateTextDetail: function(params) {
        return fetch({
            url: api.TemplateTextDetail,
            method: 'get',
            params: params
        })
    },
    // 消息类型
    TemplateTypeList: function(params) {
        return fetch({
            url: api.TemplateTypeList,
            method: 'get',
            params: params
        })
    },
    TemplateTypeUpdate: function(params) {
        return fetch({
            url: api.TemplateTypeUpdate,
            method: 'post',
            data: params
        })
    },
    TemplateTypeDelete: function(params) {
        return fetch({
            url: api.TemplateTypeDelete,
            method: 'post',
            data: params
        })
    },
    TemplateTypeDetail: function(params) {
        return fetch({
            url: api.TemplateTypeDetail,
            method: 'get',
            params: params
        })
    },
    // 下发任务
    TemplateTaskList: function(params) {
        return fetch({
            url: api.TemplateTaskList,
            method: 'get',
            params: params
        })
    },
    TemplateTaskCreate: function(params) {
        return fetch({
            url: api.TemplateTaskCreate,
            method: 'post',
            data: params
        })
    },
    TemplateTaskDelete: function(params) {
        return fetch({
            url: api.TemplateTaskDelete,
            method: 'post',
            data: params
        })
    },
    TemplateTaskCheck: function(params) {
        return fetch({
            url: api.TemplateTaskCheck,
            method: 'post',
            data: params
        })
    },
    // 涂鸦设备
    DeviceTuyaList: function(params) {
        return fetch({
            url: api.DeviceTuyaList,
            method: 'get',
            params: params
        })
    },
    DeviceTuyaSync: function(params) {
        return fetch({
            url: api.DeviceTuyaSync,
            method: 'get',
            params: params
        })
    },
    // 类目管理
    CategoryTypeList: function(params) {
        return fetch({
            url: api.CategoryTypeList,
            method: 'get',
            params: params
        })
    },
    CategoryTypeUpdate: function(params) {
        return fetch({
            url: api.CategoryTypeUpdate,
            method: 'post',
            data: params
        })
    },
    CategoryTypeDetail: function(params) {
        return fetch({
            url: api.CategoryTypeDetail,
            method: 'get',
            params: params
        })
    },
    CategoryTypeDelete: function(params) {
        return fetch({
            url: api.CategoryTypeDelete,
            method: 'post',
            data: params
        })
    },
    CategoryTypeOrder: function(params) {
        return fetch({
            url: api.CategoryTypeOrder,
            method: 'post',
            data: params
        })
    },
    // 品类管理
    CategoryList: function(params) {
        return fetch({
            url: api.CategoryList,
            method: 'get',
            params: params
        })
    },
    CategoryUpdate: function(params) {
        return fetch({
            url: api.CategoryUpdate,
            method: 'post',
            data: params
        })
    },
    CategoryDetail: function(params) {
        return fetch({
            url: api.CategoryDetail,
            method: 'get',
            params: params
        })
    },
    CategoryDelete: function(params) {
        return fetch({
            url: api.CategoryDelete,
            method: 'post',
            data: params
        })
    },
    CategoryOrder: function(params) {
        return fetch({
            url: api.CategoryOrder,
            method: 'post',
            data: params
        })
    },
    // app展示
    ClientAppShowList: function(params) {
        return fetch({
            url: api.ClientAppShowList,
            method: 'get',
            params: params
        })
    },
    ClientAppShowUpdate: function(params) {
        return fetch({
            url: api.ClientAppShowUpdate,
            method: 'post',
            data: params
        })
    },
    ClientAppShowDelete: function(params) {
        return fetch({
            url: api.ClientAppShowDelete,
            method: 'post',
            data: params
        })
    },
    ClientAppShowDetail: function(params) {
        return fetch({
            url: api.ClientAppShowDetail,
            method: 'get',
            params: params
        })
    },
    ClientAppShowOrder: function(params) {
        return fetch({
            url: api.ClientAppShowOrder,
            method: 'post',
            data: params
        })
    },
    // 品牌管理
    BrandList: function(params) {
        return fetch({
            url: api.BrandList,
            method: 'get',
            params: params
        })
    },
    BrandUpdate: function(params) {
        return fetch({
            url: api.BrandUpdate,
            method: 'post',
            data: params
        })
    },
    BrandDelete: function(params) {
        return fetch({
            url: api.BrandDelete,
            method: 'post',
            data: params
        })
    },
    BrandDetail: function(params) {
        return fetch({
            url: api.BrandDetail,
            method: 'get',
            params: params
        })
    },
    // app展示品牌
    BrandAppList: function(params) {
        return fetch({
            url: api.BrandAppList,
            method: 'get',
            params: params
        })
    },
    BrandAppUpdate: function(params) {
        return fetch({
            url: api.BrandAppUpdate,
            method: 'post',
            data: params
        })
    },
    BrandAppDelete: function(params) {
        return fetch({
            url: api.BrandAppDelete,
            method: 'post',
            data: params
        })
    },
    // 面板管理
    PanelList: function(params) {
        return fetch({
            url: api.PanelList,
            method: 'get',
            params: params
        })
    },
    PanelUpdate: function(params) {
        return fetch({
            url: api.PanelUpdate,
            method: 'post',
            data: params
        })
    },
    PanelDelete: function(params) {
        return fetch({
            url: api.PanelDelete,
            method: 'post',
            data: params
        })
    },
    PanelDetail: function(params) {
        return fetch({
            url: api.PanelDetail,
            method: 'get',
            params: params
        })
    },
    // 配网管理
    SetNetworkList: function(params) {
        return fetch({
            url: api.SetNetworkList,
            method: 'get',
            params: params
        })
    },
    SetNetworkUpdate: function(params) {
        return fetch({
            url: api.SetNetworkUpdate,
            method: 'post',
            data: params
        })
    },
    SetNetworkDelete: function(params) {
        return fetch({
            url: api.SetNetworkDelete,
            method: 'post',
            data: params
        })
    },
    SetNetworkDetail: function(params) {
        return fetch({
            url: api.SetNetworkDetail,
            method: 'get',
            params: params
        })
    },
    // 当前用户信息
    AdminerSelfInfo: function(params) {
        return fetch({
            url: api.AdminerSelfInfo,
            method: 'get',
            params: params
        })
    },
    AdminerSelfUpdate: function(params) {
        return fetch({
            url: api.AdminerSelfUpdate,
            method: 'post',
            data: params
        })
    },
    // bannel
    BannelList: function(params) {
        return fetch({
            url: api.BannelList,
            method: 'get',
            params: params
        })
    },
    // 订单
    OrderList: function(params) {
        return fetch({
            url: api.OrderList,
            method: 'get',
            params: params
        })
    },
    OrderInfo: function(params) {
        return fetch({
            url: api.OrderInfo,
            method: 'get',
            params: params
        })
    },
    OrderSubscribe: function(params) {
        return fetch({
            url: api.OrderSubscribe,
            method: 'get',
            params: params
        })
    },
    OrderSubscribeInfo: function(params) {
        return fetch({
            url: api.OrderSubscribeInfo,
            method: 'get',
            params: params
        })
    },
    OrderCloudInfo: function(params) {
        return fetch({
            url: api.OrderCloudInfo,
            method: 'get',
            params: params
        })
    },
    // 内容管理状态修改
    ContentStatus: function(params) {
        return fetch({
            url: api.ContentStatus,
            method: 'post',
            data: params
        })
    },
    RuleStatus: function(params) {
        return fetch({
            url: api.RuleStatus,
            method: 'post',
            data: params
        })
    },
    RulePageStatus: function(params) {
        return fetch({
            url: api.RulePageStatus,
            method: 'post',
            data: params
        })
    },
    // alexa 类型 列表
    AlexaCategoryList: function(params) {
        return fetch({
            url: api.AlexaCategoryList,
            method: 'get',
            params: params
        })
    },
    // 社媒列表
    BrandMediaList: function(params) {
        return fetch({
            url: api.BrandMediaList,
            method: 'get',
            params: params
        })
    },
    BrandMediaUpdate: function(params) {
        return fetch({
            url: api.BrandMediaUpdate,
            method: 'post',
            data: params
        })
    },
    BrandMediaDelete: function(params) {
        return fetch({
            url: api.BrandMediaDelete,
            method: 'post',
            data: params
        })
    },
    BrandMediaDetail: function(params) {
        return fetch({
            url: api.BrandMediaDetail,
            method: 'get',
            params: params
        })
    },
    // 统计看板
    BoardViewUser: function(params) {
        return fetch({
            url: api.BoardViewUser,
            method: 'get',
            params: params
        })
    },
    BoardViewUser_Day: function(params) {
        return fetch({
            url: api.BoardViewUser_Day,
            method: 'get',
            params: params
        })
    },
    BoardViewUser_Subscription: function(params) {
        return fetch({
            url: api.BoardViewUser_Subscription,
            method: 'get',
            params: params
        })
    },
    BoardViewUser_Revenue: function(params) {
        return fetch({
            url: api.BoardViewUser_Revenue,
            method: 'get',
            params: params
        })
    },
    BoardViewUser_Revenue_Day: function(params) {
        return fetch({
            url: api.BoardViewUser_Revenue_Day,
            method: 'get',
            params: params
        })
    },
    BoardViewUser_device: function(params) {
        return fetch({
            url: api.BoardViewUser_device,
            method: 'get',
            params: params
        })
    },
    BoardViewUser_device_sub: function(params) {
        return fetch({
            url: api.BoardViewUser_device_sub,
            method: 'get',
            params: params
        })
    },
    BoardViewUser_device_Day: function(params) {
        return fetch({
            url: api.BoardViewUser_device_Day,
            method: 'get',
            params: params
        })
    },
    BoardViewUser_camera: function(params) {
        return fetch({
            url: api.BoardViewUser_camera,
            method: 'get',
            params: params
        })
    },
    BoardViewUser_camera_sub: function(params) {
        return fetch({
            url: api.BoardViewUser_camera_sub,
            method: 'get',
            params: params
        })
    },
    BoardViewUser_camera_Day: function(params) {
        return fetch({
            url: api.BoardViewUser_camera_Day,
            method: 'get',
            params: params
        })
    },
    // 云存储
    CancelCloudRenew: function(params) {
        return fetch({
            url: api.CancelCloudRenew,
            method: 'post',
            data: params
        })
    },
    CloseCloud: function(params) {
        return fetch({
            url: api.CloseCloud,
            method: 'post',
            data: params
        })
    },
    // Rn 面板
    RnPanelTypes: function(params) {
        return fetch({
            url: api.RnPanelTypes,
            method: 'get',
            params: params
        })
    },
    RnPanelTypeCreate: function(params) {
        return fetch({
            url: api.RnPanelTypeCreate,
            method: 'post',
            data: params
        })
    },
    RnPanelVersions: function(params) {
        return fetch({
            url: api.RnPanelVersions,
            method: 'get',
            params: params
        })
    },
    RnPanelVersionInfo: function(params) {
        return fetch({
            url: api.RnPanelVersionInfo,
            method: 'get',
            params: params
        })
    },
    RnPanelUpload: function(params) {
        return fetch({
            url: api.RnPanelUploadUrl,
            method: 'post',
            data: params
        })
    },
    RnPanelUpdateStatus: function(params) {
        return fetch({
            url: api.RnPanelUpdateStatus,
            method: 'post',
            data: params
        })
    },
    RnPanelTypeDistribute: function(params) {
        return fetch({
            url: api.RnPanelTypeDistribute,
            method: 'post',
            data: params
        })
    },
    RnPanelTypeModels: function(params) {
        return fetch({
            url: api.RnPanelTypeModels,
            method: 'get',
            params: params
        })
    },
    TslAttributeCreate: function(params) {
        return fetch({
            url: api.TslAttributeCreate,
            method: 'post',
            data: params
        })
    },
    TslAttributeList: function(params) {
        return fetch({
            url: api.TslAttributeList,
            method: 'get',
            params: params
        })
    },
    TslAttributeDelete: function(params) {
        return fetch({
            url: api.TslAttributeDelete,
            method: 'post',
            data: params
        })
    },
    WarrantyOrderList: function(params) {
        return fetch({
            url: api.WarrantyOrderList,
            method: 'get',
            params: params
        })
    },
    WarrantyOrderUpdate: function(params) {
        return fetch({
            url: api.WarrantyOrderUpdate,
            method: 'put',
            data: params
        })
    },
    InviteCommentCreate: function(params) {
        return fetch({
            url: api.InviteCommentCreate,
            method: 'post',
            data: params
        })
    },
    InviteCommentList: function(params) {
        return fetch({
            url: api.InviteCommentList,
            method: 'get',
            params: params
        })
    },
    InviteCommentUpdate: function(params) {
        return fetch({
            url: api.InviteCommentUpdate,
            method: 'post',
            data: params
        })
    },
    InviteCommentDelete: function(params) {
        return fetch({
            url: api.InviteCommentDelete,
            method: 'post',
            data: params
        })
    }
}

<template>
  <div id="app">
    <router-view></router-view>
    <!-- <router-view v-if="isRouterAlive"></router-view> -->
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {},
  data () {
    return {
      isRouterAlive: true
    }
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
  },
  mounted () {
  }
}
</script>

<style>
html,body,#app,.container {
    height:100%;
  }
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>

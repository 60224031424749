const login = () => import('../page/login')
const index = () => import('../page/index')

const baseinfo = () => import('../page/baseInfo/index')
const country = () => import('../page/baseInfo/basechildren/country')
const currency = () => import('../page/baseInfo/basechildren/currency')
const Region = () => import('../page/baseInfo/basechildren/regin')

const language = () => import('../page/baseInfo/basechildren/language')
const admin = () => import('../page/admin/index')
const adminer = () => import('../page/admin/adminchildren/admin/index')
const adminpower = () => import('../page/admin/adminchildren/admin/adminpower')
const funcmanage = () => import('../page/admin/adminchildren/func/index')
const menumanage = () => import('../page/admin/adminchildren/menu/index')
const menuFunc = () => import('../page/admin/adminchildren/menu/menufunc')
const role = () => import('../page/admin/adminchildren/role/index')
const rolePower = () => import('../page/admin/adminchildren/role/power')
const iotmanage = () => import('../page/iotmanage/index')
// 品类管理
const CategoryType = () => import('../page/iotmanage/category/categoryType')
const categoryManage = () => import('../page/iotmanage/category/categoryManage')
const Brand = () => import('../page/iotmanage/brand/index')
const Media = () => import('../page/iotmanage/brand/media')
const panel = () => import('../page/iotmanage/panel/index')
const Paneladd = () => import('../page/iotmanage/panel/paneladd')
const Distributenet = () => import('../page/iotmanage/distributenet/index')
const Function = () => import('../page/iotmanage/function/index')
const Plan = () => import('../page/iotmanage/plan/index')
const distributefun = () => import('../page/iotmanage/plan/distributefun')
const developer = () => import('../page/iotmanage/developer/index')
const authentic = () => import('../page/iotmanage/developer/authentic')
const multilanguage = () => import('../page/iotmanage/multilanguage/index')
const pageelement = () => import('../page/iotmanage/multilanguage/pageEle')
const eleTrans = () => import('../page/iotmanage/multilanguage/eleTrans')
const Order = () => import('../page/iotmanage/order/index')
const Subscribe = () => import('../page/iotmanage/order/subscribe')

const iot = () => import('../page/iot/index')
const cotentManage = () => import('../page/iot/cotentManage/index')
const Banner = () => import('../page/iot/cotentManage/banner/index')
const cotentindex = () => import('../page/iot/cotentManage/contentindex')
const Rule = () => import('../page/iot/cotentManage/rulemanag')
const content = () => import('../page/iot/cotentManage/content')
const contentAdd = () => import('../page/iot/cotentManage/contentadd')

const appmanage = () => import('../page/iot/appVersion/index')
const appShow = () => import('../page/iot/appVersion/push/appshow')
const Brandshow = () => import('../page/iot/appVersion/push/brandshow')
const appVersion = () => import('../page/iot/appVersion/push/index')
const apns = () => import('../page/iot/appVersion/push/apnspush')
const apnsAdd = () => import('../page/iot/appVersion/push/apnsadd')
const fcm = () => import('../page/iot/appVersion/push/fcmpush')
const fcmAdd = () => import('../page/iot/appVersion/push/fcmadd')
const getui = () => import('../page/iot/appVersion/push/getuipush')
const getuiAdd = () => import('../page/iot/appVersion/push/getuiadd')
const jiguang = () => import('../page/iot/appVersion/push/jiguangpush')
const youmeng = () => import('../page/iot/appVersion/push/youmeng')
const addappversion = () => import('../page/iot/appVersion/push/addappversion')
const solid = () => import('../page/iot/appVersion/push/solid')
const addsolid = () => import('../page/iot/appVersion/push/addsolid')
const appEmail = () => import('../page/iot/appVersion/push/emailconfig')
const appEmailAdd = () => import('../page/iot/appVersion/push/emailconfigadd')
// const prodevelop = () => import('../page/iot/prodevelop/index')
// const editPro = () => import('../page/iot/prodevelop/editPro')
const ProModel = () => import('../page/iot/modelManage/index')
const Model = () => import('../page/iot/modelManage/model')
const ModelList = () => import('../page/iot/modelList/index')
const DistributeModel = () => import('../page/iot/modelManage/distributemodel')
const ModelConfig = () => import('../page/iot/modelManage/modelconfig')
const Factory = () => import('../page/iot/modelManage/factory')
const ProductModelAttribute = () => import('../page/iot/modelManage/attributes')
const Production = () => import('../page/iot/production/index')
const ProductionAdd = () => import('../page/iot/production/productionAdd')
// const Delivery = () => import('../page/iot/production/delivery')

// 版本
const Version = () => import('../page/iot/versionManage/index')
const Soft = () => import('../page/iot/versionManage/soft/index')
const addversionSoft = () => import('../page/iot/versionManage/soft/softadd')
const SoftdisSolid = () => import('../page/iot/versionManage/soft/solid')
const versionSolid = () => import('../page/iot/versionManage/solid/index')
const addversionSolid = () => import('../page/iot/versionManage/solid/solidadd')
const solidRegion = () => import('../page/iot/versionManage/solid/regionStatus')
const ForceUpgrade = () =>
    import('../page/iot/versionManage/forceupgrade/index')
const AddForceUpgrade = () =>
    import('../page/iot/versionManage/forceupgrade/forceupgradeadd')

// 设备出厂
const DevideManage = () => import('../page/iot/dilivery/index')
const Delivery = () => import('../page/iot/dilivery/delivery')
const Online = () => import('../page/iot/dilivery/online')
const OnlineInfo = () => import('../page/iot/dilivery/onlineInfo')
const TuyaList = () => import('../page/iot/dilivery/tuya')
// 客户授权
const Client = () => import('../page/iot/client/index')
const ClientAdd = () => import('../page/iot/client/clientadd')
// 套餐管理
const Package = () => import('../page/iot/packmanage/index')
const PackageAdd = () => import('../page/iot/packmanage/packadd')
const PackageSale = () => import('../page/iot/packmanage/sale')
const PackageDistribute = () => import('../page/iot/packmanage/packdistribute')

// 优惠券
const Coupon = () => import('../page/iot/coupon/index')

const User = () => import('../page/iot/userManage/index')
const UserInfo = () => import('../page/iot/userManage/userinfo')
const BindingInfo = () => import('../page/iot/userManage/bindingInfo')
const DeleteAccount = () => import('../page/iot/userManage/deleteAccount')

const Authorization = () => import('../page/iot/authorization/index')
const Feedbacktype = () => import('../page/iot/feedbackType/index')
const FeedbackList = () => import('../page/iot/feedbackList/index')
const FeedbackListInfo = () =>
    import('../page/iot/feedbackList/feedbacklistInfo')
const FeedbackManage = () => import('../page/iot/feedbackManage/index')
const S3List = () => import('../page/iot/s3List/index')
const AmazonOrderList = () => import('../page/iot/amazonOrderList/index')
const InviteCommentList = () => import('../page/iot/inviteCommentList/index')   
const S3keyAnalyse = () => import('../page/iotmanage/s3keyAnalyse/index')

const Message = () => import('../page/message/index')
const Sysmessage = () => import('../page/message/sysmessage/index')
const Devmessage = () => import('../page/message/devicemessage/index')
const Actmessage = () => import('../page/message/actmessage/index')
const Sendmessage = () => import('../page/message/sendmessage/index')
const Emailtype = () => import('../page/message/sendermanage/emailtype')
const SenderList = () => import('../page/message/sendermanage/index')
const SenderUser = () => import('../page/message/sendermanage/user')

// 模板消息
const TemplateMsgList = () => import('../page/message/templateMsg/index')
const TemplateMsgAdd = () =>
    import('../page/message/templateMsg/templateMsgAdd')
const EmailMsgAdd = () => import('../page/message/templateMsg/emailMsgAdd')
const ImgMsgAdd = () => import('../page/message/templateMsg/imgMsgAdd')
const TextMsgAdd = () => import('../page/message/templateMsg/textMsgAdd')
const DistributeTsk = () => import('../page/message/templateMsg/distributeTsk')
const MessageType = () => import('../page/message/messageType/index')
const MessagePush = () => import('../page/message/templateMsg/messagePush')

// 生产管理
const ProductionManage = () => import('../page/productionManage/index')
const FactoryManage = () => import('../page/productionManage/factoryManage')
const FactoryModel = () => import('../page/productionManage/factoryModel')
const ProductionApply = () => import('../page/productionManage/productionApply')
const EditApply = () => import('../page/productionManage/editApply')
const ProductionAudit = () => import('../page/productionManage/productionAudit')
const DeviceDelivery = () => import('../page/productionManage/deviceDelivery')

// 物模型
const TslAttribute = () => import('../page/iot/tsl/attribute')
const TslAttributeAdd = () => import('../page/iot/tsl/add_attribute')

// RN面板
const panelTypeList = () => import('../page/iot/panel/types')
const panelVersionList = () => import('../page/iot/panel/versions')
const panelVersionUpload = () => import('../page/iot/panel/upload')

// BI
const BI = () => import('../page/iot/BI/index')
const Nooie = () => import('../page/iot/BI/Nooie/index')

export default [
    {
        path: '/',
        component: login
    },
    {
        path: '/login',
        component: login
    },
    {
        path: '/index',
        component: index,
        children: [
            {
                path: '/baseinfo',
                component: baseinfo,
                children: [
                    {
                        path: '',
                        redirect: '/baseinfo/country'
                    },
                    {
                        path: '/baseinfo/country',
                        component: country,
                        meta: ['基础信息配置', '国家信息']
                    },
                    {
                        path: '/baseinfo/language',
                        component: language,
                        meta: ['基础信息配置', '语言信息']
                    },
                    {
                        path: '/baseinfo/currency',
                        component: currency,
                        meta: ['基础信息配置', '币种']
                    },
                    {
                        path: '/baseinfo/region',
                        component: Region,
                        meta: ['基础信息配置', '区域配置']
                    }
                ]
            },
            {
                path: '/admin',
                component: admin,
                children: [
                    {
                        path: '',
                        redirect: '/admin/adminer'
                    },
                    {
                        path: '/admin/adminer',
                        component: adminer,
                        meta: ['后台管理', '管理员管理']
                    },
                    {
                        path: '/admin/adminer/power/:id',
                        component: adminpower,
                        meta: ['后台管理', '管理员管理', '权限分配']
                    },
                    {
                        path: '/admin/role',
                        component: role,
                        meta: ['后台管理', '角色管理']
                    },
                    {
                        path: '/admin/role/rolePower/:id',
                        component: rolePower,
                        meta: ['后台管理', '角色管理', '角色权限分配']
                    },
                    {
                        path: '/admin/menu',
                        component: menumanage,
                        meta: ['后台管理', '菜单管理']
                    },
                    {
                        path: '/admin/menu/menufunc/:menu',
                        component: menuFunc,
                        meta: ['后台管理', '菜单管理', '菜单功能']
                    },
                    {
                        path: '/admin/func',
                        component: funcmanage,
                        meta: ['后台管理', '功能管理']
                    }
                ]
            },
            {
                path: '/iotmanage',
                component: iotmanage,
                children: [
                    {
                        path: '',
                        redirect: '/iotmanage/multilanguage'
                    },
                    {
                        path: '/iotmanage/categorytype',
                        component: CategoryType,
                        meta: ['iot平台管理', '类目管理']
                    },
                    {
                        path: '/iotmanage/category/categorymanage/:code',
                        component: categoryManage,
                        meta: ['iot平台管理', '类目管理', '品类管理']
                    },
                    {
                        path: '/iotmanage/brand',
                        component: Brand,
                        meta: ['iot平台管理', '品牌管理']
                    },
                    {
                        path: '/iotmanage/brand/media/:code',
                        component: Media,
                        meta: ['iot平台管理', '品牌管理', '社媒列表']
                    },
                    {
                        path: '/iotmanage/panel',
                        component: panel,
                        meta: ['iot平台管理', '面板管理']
                    },
                    {
                        path: '/iotmanage/paneladd/:code',
                        component: Paneladd,
                        meta: ['iot平台管理', '面板编辑']
                    },
                    {
                        path: '/iotmanage/distributenet',
                        component: Distributenet,
                        meta: ['iot平台管理', '配网管理']
                    },
                    {
                        path: '/iotmanage/function',
                        component: Function,
                        meta: ['iot平台管理', '功能管理']
                    },
                    {
                        path: '/iotmanage/plan',
                        component: Plan,
                        meta: ['iot平台管理', '方案管理']
                    },
                    {
                        path: '/iotmanage/plan/distributefun',
                        component: distributefun,
                        meta: ['iot平台管理', '方案管理', '分配功能']
                    },
                    {
                        path: '/iotmanage/developer',
                        component: developer,
                        meta: ['iot平台管理', '开发者管理']
                    },
                    {
                        path: '/iotmanage/developer/authentic',
                        component: authentic,
                        meta: ['iot平台管理', '开发者管理', '公司认证审核']
                    },
                    {
                        path: '/iotmanage/multilanguage',
                        component: multilanguage,
                        meta: ['iot平台管理', '多语言页面管理']
                    },
                    {
                        path: '/iotmanage/multilanguage/pageelement/:page',
                        component: pageelement,
                        meta: ['iot平台管理', '多语言页面管理', '页面元素']
                    },
                    {
                        path:
                            '/iotmanage/multilanguage/pageelement/eletrans/:code',
                        component: eleTrans,
                        meta: [
                            'iot平台管理',
                            '多语言页面管理',
                            '页面元素',
                            '元素翻译'
                        ]
                    },
                    {
                        path: '/iotmanage/s3keyanalyse',
                        component: S3keyAnalyse,
                        meta: ['iot平台管理', 's3key分析']
                    },
                    {
                        path: '/iotmanage/order',
                        component: Order,
                        meta: ['iot平台管理', '订单管理']
                    },
                    {
                        path: '/iotmanage/subscribe',
                        component: Subscribe,
                        meta: ['iot平台管理', '订单管理', '订阅管理']
                    }
                ]
            },
            {
                path: '/iot',
                component: iot,
                children: [
                    {
                        path: '',
                        redirect: '/iot/content'
                    },
                    {
                        path: '/iot/content',
                        component: cotentManage,
                        meta: ['iot平台', '内容管理'],
                        children: [
                            {
                                path: '',
                                redirect: '/iot/contentmanage'
                            },
                            {
                                path: '/iot/contentmanage',
                                component: cotentindex,
                                meta: ['iot平台', '内容管理']
                            },
                            {
                                path: '/iot/contentmanage/rule/:code',
                                component: Rule,
                                meta: ['iot平台', '内容管理', '规则管理']
                            },
                            {
                                path: '/iot/contentmanage/rule/content/:code',
                                component: content,
                                meta: [
                                    'iot平台',
                                    '内容管理',
                                    '规则管理',
                                    '内容页'
                                ]
                            },
                            {
                                path:
                                    '/iot/contentmanage/rule/contentadd/:code',
                                component: contentAdd,
                                meta: [
                                    'iot平台',
                                    '内容管理',
                                    '规则管理',
                                    '内容页编辑'
                                ]
                            },
                            {
                                path: '/iot/banner',
                                component: Banner,
                                meta: ['iot平台', 'Bannel管理']
                            }
                        ]
                    },
                    {
                        path: '/iot/app',
                        component: appmanage,
                        meta: ['iot平台', 'app版本管理'],
                        children: [
                            {
                                path: '',
                                redirect: '/iot/app/appvesion'
                            },
                            {
                                path: '/iot/app/appvesion',
                                component: appVersion,
                                meta: ['iot平台', 'app列表']
                            },
                            {
                                path: '/iot/app/appshow/:code',
                                component: appShow,
                                meta: ['iot平台', 'app列表', 'app展示类目管理']
                            },
                            {
                                path: '/iot/app/brandshow/:code',
                                component: Brandshow,
                                meta: ['iot平台', 'app列表', 'app展示品牌管理']
                            },
                            {
                                path: '/iot/app/apns',
                                component: apns,
                                meta: ['iot平台', 'app管理', 'apns']
                            },
                            {
                                path: '/iot/app/apnsadd/:id',
                                component: apnsAdd,
                                meta: ['iot平台', 'app管理', 'apns', '编辑apns']
                            },
                            {
                                path: '/iot/app/fcm',
                                component: fcm,
                                meta: ['iot平台', 'app管理', 'fcm']
                            },
                            {
                                path: '/iot/app/fcmadd/:id',
                                component: fcmAdd,
                                meta: ['iot平台', 'app管理', 'fcm', '编辑fcm']
                            },
                            {
                                path: '/iot/app/getui',
                                component: getui,
                                meta: ['iot平台', 'app管理', 'getui']
                            },
                            {
                                path: '/iot/app/getuiadd/:id',
                                component: getuiAdd,
                                meta: [
                                    'iot平台',
                                    'app管理',
                                    'getui',
                                    '新增getui'
                                ]
                            },
                            {
                                path: '/iot/app/jiguang',
                                component: jiguang,
                                meta: ['iot平台', 'app管理', 'jiguang']
                            },
                            {
                                path: '/iot/app/youmeng',
                                component: youmeng,
                                meta: ['iot平台', 'app管理', 'youmeng']
                            },
                            {
                                path: '/iot/app/appvesion/addappversion/:code',
                                component: addappversion,
                                meta: ['iot平台', 'app版本管理', '新增app版本']
                            },
                            {
                                path: '/iot/app/appvesion/solid',
                                component: solid,
                                meta: ['iot平台', 'app版本管理', '固件包']
                            },
                            {
                                path: '/iot/app/appvesion/solid/addsolid',
                                component: addsolid,
                                meta: [
                                    'iot平台',
                                    'app版本管理',
                                    '固件包',
                                    '固件包新增'
                                ]
                            },
                            {
                                path: '/iot/app/email',
                                component: appEmail,
                                meta: ['iot平台', 'app管理', '邮箱配置']
                            },
                            {
                                path: '/iot/app/emailedit/:id',
                                component: appEmailAdd,
                                meta: ['iot平台', 'app管理', '邮箱配置新增']
                            }
                        ]
                    },
                    // {
                    //     path: "/iot/prodevelop",
                    //     component: prodevelop,
                    //     meta:['iot平台','产品开发'],
                    // },
                    // {
                    //     path: "/iot/prodevelop/editPro",
                    //     component: editPro,
                    //     meta:['iot平台','产品开发','编辑产品'],
                    // },
                    {
                        path: '/iot/device',
                        component: DevideManage,
                        meta: ['iot平台', '设备管理'],
                        children: [
                            {
                                path: '',
                                redirect: '/iot/delivery'
                            },
                            {
                                path: '/iot/delivery',
                                component: Delivery,
                                meta: ['iot平台', '设备管理', '设备出厂']
                            },
                            {
                                path: '/iot/online',
                                component: Online,
                                meta: ['iot平台', '设备管理', '自研设备']
                            },
                            {
                                path: '/iot/onlineinfo/:code',
                                component: OnlineInfo,
                                meta: ['iot平台', '设备管理', '自研设备详情']
                            },
                            {
                                path: '/iot/tuya',
                                component: TuyaList,
                                meta: ['iot平台', '涂鸦设备']
                            }
                        ]
                    },
                    {
                        path: '/iot/pack',
                        component: Package,
                        meta: ['iot平台', '套餐管理']
                    },
                    {
                        path: '/iot/packadd/:code',
                        component: PackageAdd,
                        meta: ['iot平台', '套餐管理新增']
                    },
                    {
                        path: '/iot/pack/sale/:code',
                        component: PackageSale,
                        meta: ['iot平台', '套餐管理', '套餐折扣']
                    },
                    {
                        path: '/iot/pack/distribute/:code',
                        component: PackageDistribute,
                        meta: ['iot平台', '套餐管理', '套餐分配']
                    },
                    {
                        path: '/iot/coupon',
                        component: Coupon,
                        meta: ['iot平台', '优惠券']
                    },
                    {
                        path: '/iot/client',
                        component: Client,
                        meta: ['iot平台', '客户授权']
                    },
                    {
                        path: '/iot/client/add/:id',
                        component: ClientAdd,
                        meta: ['iot平台', '客户授权新增']
                    },
                    {
                        path: '/iot/promodel',
                        component: ProModel,
                        meta: ['iot平台', '产品']
                    },
                    {
                        path: '/iot/model',
                        component: ModelList,
                        meta: ['iot平台', '产品型号列表']
                    },
                    {
                        path: '/iot/model/attributes',
                        component: ProductModelAttribute,
                        meta: ['iot平台', '产品型号列表', '属性']
                    },
                    {
                        path: '/iot/promodel/model/:code',
                        component: Model,
                        meta: ['iot平台', '产品', '型号配置']
                    },
                    {
                        path: '/iot/promodel/distributemodel/:code',
                        component: DistributeModel,
                        meta: ['iot平台', '产品', '型号分配']
                    },
                    {
                        path: '/iot/promodel/modelconfig/:code',
                        component: ModelConfig,
                        meta: ['iot平台', '型号配置详情']
                    },
                    {
                        path: '/iot/promodel/factory',
                        component: Factory,
                        meta: ['iot平台', '产品', '生产工厂']
                    },
                    {
                        path: '/iot/version',
                        component: Version,
                        meta: ['iot平台', '版本管理'],
                        children: [
                            {
                                path: '',
                                redirect: '/iot/version/solid'
                            },
                            {
                                path: '/iot/version/soft',
                                component: Soft,
                                meta: ['iot平台', '版本管理', '软件版本']
                            },
                            {
                                path: '/iot/version/soft/update/:code',
                                component: addversionSoft,
                                meta: [
                                    'iot平台',
                                    '版本管理',
                                    '软件版本',
                                    '编辑软件版本'
                                ]
                            },
                            {
                                path: '/iot/version/soft/dissolid/:code',
                                component: SoftdisSolid,
                                meta: [
                                    'iot平台',
                                    '版本管理',
                                    '软件版本',
                                    '分配固件包'
                                ]
                            },
                            {
                                path: '/iot/version/solid',
                                component: versionSolid,
                                meta: ['iot平台', '版本管理', '固件版本']
                            },
                            {
                                path: '/iot/version/solid/update/:code',
                                component: addversionSolid,
                                meta: [
                                    'iot平台',
                                    '版本管理',
                                    '固件版本',
                                    '编辑固件版本'
                                ]
                            },
                            {
                                path: '/iot/version/solid/regionstatus/:code',
                                component: solidRegion,
                                meta: [
                                    'iot平台',
                                    '版本管理',
                                    '固件版本',
                                    '区域状态'
                                ]
                            },
                            {
                                path: '/iot/version/forceupgrade',
                                component: ForceUpgrade,
                                meta: ['iot平台', '版本管理', '强制升级']
                            },
                            {
                                path: '/iot/version/forceupgrade/update/:code',
                                component: AddForceUpgrade,
                                meta: [
                                    'iot平台',
                                    '版本管理',
                                    '强制升级',
                                    '编辑强制升级'
                                ]
                            }
                        ]
                    },
                    {
                        path: '/iot/production',
                        component: Production,
                        meta: ['iot平台', '生产管理']
                    },
                    {
                        path: '/iot/production/add',
                        component: ProductionAdd,
                        meta: ['iot平台', '生产管理', '新增生产管理']
                    },
                    // {
                    //     path: "/iot/production/delivery",
                    //     component: Delivery,
                    //     meta:['iot平台','生产管理','设备出厂'],
                    // },
                    {
                        path: '/iot/user',
                        component: User,
                        meta: ['iot平台', '用户管理']
                    },
                    {
                        path: '/iot/userinfo/:uid',
                        component: UserInfo,
                        meta: ['iot平台', '用户管理详情']
                    },
                    {
                        path: '/iot/user/device/:uid',
                        component: BindingInfo,
                        meta: ['iot平台', '用户管理', '绑定设备']
                    },
                    {
                        path: '/iot/user/deleteaccount',
                        component: DeleteAccount,
                        meta: ['iot平台', '用户管理', '用户删除申请表']
                    },
                    {
                        path: '/iot/authorization',
                        component: Authorization,
                        meta: ['iot平台', '授权应用']
                    },
                    {
                        path: '/iot/feedbacktype',
                        component: Feedbacktype,
                        meta: ['iot平台', '反馈类型']
                    },
                    {
                        path: '/iot/feedbackmanage',
                        component: FeedbackManage,
                        meta: ['iot平台', '反馈管理']
                    },
                    {
                        path: '/iot/feedbacklist',
                        component: FeedbackList,
                        meta: ['iot平台', '反馈列表']
                    },
                    {
                        path: '/iot/feedbackinfo/:code',
                        component: FeedbackListInfo,
                        meta: ['iot平台', '反馈详情']
                    },
                    {
                        path: '/iot/s3list',
                        component: S3List,
                        meta: ['iot平台', 's3列表']
                    },
                    {
                        path: '/iot/tsl',
                        component: TslAttribute,
                        meta: ['物模型', '属性列表']
                    },
                    {
                        path: '/iot/tsl/add',
                        component: TslAttributeAdd,
                        meta: ['物模型', '添加属性']
                    },
                    {
                        path: '/iot/rn/types',
                        component: panelTypeList,
                        meta: ['RN面板', '类型']
                    },
                    {
                        path: '/iot/rn/versions',
                        component: panelVersionList,
                        meta: ['RN面板', '包列表']
                    },
                    {
                        path: '/iot/rn/upload',
                        component: panelVersionUpload,
                        meta: ['RN面板', '上传']
                    },
                    {
                        path: '/iot/bi',
                        component: BI,
                        meta: ['iot平台', '看板管理'],
                        children: [
                            {
                                path: '',
                                redirect: '/iot/bi/nooie'
                            },
                            {
                                path: '/iot/bi/nooie',
                                component: Nooie,
                                meta: ['iot平台', 'Nooie看板']
                            }
                        ]
                    },
                    {
                        path: '/iot/amazonorderlist',
                        component: AmazonOrderList,
                        meta: ['iot平台', '亚马逊订单号']
                    },
                    {
                        path: '/iot/invitecommentlist',
                        component: InviteCommentList,
                        meta: ['iot平台', '邀评链接']
                    }
                ]
            },
            {
                path: '/message',
                component: Message,
                children: [
                    {
                        path: '',
                        redirect: '/message/sysmessage'
                    },
                    {
                        path: '/message/sysmessage',
                        component: Sysmessage,
                        meta: ['消息管理', '系统消息']
                    },
                    {
                        path: '/message/devmessage',
                        component: Devmessage,
                        meta: ['消息管理', '设备消息']
                    },
                    {
                        path: '/message/actmessage',
                        component: Actmessage,
                        meta: ['消息管理', '活动消息']
                    },
                    {
                        path: '/message/sendmessage',
                        component: Sendmessage,
                        meta: ['消息管理', '下发消息']
                    },
                    {
                        path: '/message/emailtype',
                        component: Emailtype,
                        meta: ['消息管理', '邮箱类型']
                    },
                    {
                        path: '/message/sender',
                        component: SenderList,
                        meta: ['消息管理', '发件人列表']
                    },
                    {
                        path: '/message/sender/user/:code',
                        component: SenderUser,
                        meta: ['消息管理', '发件人列表', '使用者']
                    },
                    // 模板消息
                    {
                        path: '/message/templatemessage',
                        component: TemplateMsgList,
                        meta: ['消息管理', '消息模板']
                    },
                    {
                        path: '/message/templatemessageadd/:code',
                        component: TemplateMsgAdd,
                        meta: ['消息管理', '消息模板编辑']
                    },
                    {
                        path: '/message/emailmsgadd/:code',
                        component: EmailMsgAdd,
                        meta: ['消息管理', '消息模板编辑', '邮件消息模板编辑']
                    },
                    {
                        path: '/message/imgmsgadd/:code',
                        component: ImgMsgAdd,
                        meta: ['消息管理', '消息模板编辑', '图文消息模板编辑']
                    },
                    {
                        path: '/message/textmsgadd/:code',
                        component: TextMsgAdd,
                        meta: ['消息管理', '消息模板编辑', '文本消息模板编辑']
                    },
                    {
                        path: '/message/distributetsk/:code',
                        component: DistributeTsk,
                        meta: ['消息管理', '下发任务']
                    },
                    {
                        path: '/message/mespush/:code',
                        component: MessagePush,
                        meta: ['消息管理', '消息推送']
                    },
                    {
                        path: '/message/messagetype',
                        component: MessageType,
                        meta: ['消息管理', '消息类型']
                    }
                ]
            },
            {
                path: '/product',
                component: ProductionManage,
                children: [
                    {
                        path: '',
                        redirect: '/product/factorymanage'
                    },
                    {
                        path: '/product/factorymanage',
                        component: FactoryManage,
                        meta: ['生产管理', '工厂管理']
                    },
                    {
                        path: '/product/factorymodel/:code',
                        component: FactoryModel,
                        meta: ['生产管理', '工厂管理', '关联产品型号']
                    },
                    {
                        path: '/product/productionapply',
                        component: ProductionApply,
                        meta: ['生产管理', '生产申请']
                    },
                    {
                        path: '/product/editapply/:code',
                        component: EditApply,
                        meta: ['生产管理', '生产申请', '编辑生产申请']
                    },
                    {
                        path: '/product/productionaudit',
                        component: ProductionAudit,
                        meta: ['生产管理', '生产审核']
                    },
                    {
                        path: '/product/devicedelivery',
                        component: DeviceDelivery,
                        meta: ['生产管理', '出厂设备']
                    }
                ]
            }
        ]
    }
]
